<template>
  <div class="zhe" @scroll="showIcon">
    <div class="form_top">
      <div class="top_left">问卷调查</div>
      <div class="top_right">
        <el-button type="primary" style="width:75px;text-align: center" size="small" @click="submit">保存</el-button>
        <el-button size="small" style="width:75px;text-align: center;margin-right: 20px;" @click="back">取消</el-button>
      </div>
    </div>
    <div style="width: 100%;height: 51px;"></div>
    <div class="form_bottom">
      <div class="form_left">
        <a href="#I" @click="int = 0" :class="['menu_item',{menu_item_a:int === 0}]">I .人口统计学及入院信息</a>
        <a href="#II" @click="int = 1" :class="['menu_item',{menu_item_a:int === 1}]">II.实验室检查结果</a>
        <a href="#III" @click="int = 2" :class="['menu_item',{menu_item_a:int === 2}]" class="menu_item">III.影像学检查结果</a>
        <a href="#IV" @click="int = 3" :class="['menu_item',{menu_item_a:int === 3}]" class="menu_item">IV.术前膝关节功能评分</a>
        <a href="#V" @click="int = 4" :class="['menu_item',{menu_item_a:int === 4}]" class="menu_item">V.手术时年龄</a>
        <a href="#VI" @click="int = 5" :class="['menu_item',{menu_item_a:int === 5}]" class="menu_item">VI.治疗方式</a>
        <a href="#VII" @click="int = 6" :class="['menu_item',{menu_item_a:int === 6}]" class="menu_item">VII.关节镜所见</a>
        <a href="#VIII" @click="int = 7" :class="['menu_item',{menu_item_a:int === 7}]" class="menu_item">VIII.术后并发症</a>
        <a href="#IX" @click="int = 8" :class="['menu_item',{menu_item_a:int === 8}]" class="menu_item">IX.术后负重时间</a>
        <a href="#X" @click="int = 9" :class="['menu_item',{menu_item_a:int === 9}]" class="menu_item">X.术后是否进行规范化、系统性功能锻炼</a>
        <a href="#X" @click="int = 10" :class="['menu_item',{menu_item_a:int === 10}]" class="menu_item">XI.术后疗效评估</a>
        <a href="#X" @click="int = 11" :class="['menu_item',{menu_item_a:int === 11}]" class="menu_item">XII.随访期间对膝关节不适的处理措施</a>
        <a href="#X" @click="int = 12" :class="['menu_item',{menu_item_a:int === 12}]" class="menu_item">XIII.其他需要特殊说明的情况</a>
      </div>
      <div class="form_right">
        <div class="right_center">
          <img class="right_logo" src="../assets/images/u5690.gif" alt="">
          <div class="right_title">人类膝关节外侧盘状半月板遗传方式及致病基因研究信息提取表</div>
          <div class="info">
            <span>填报人</span>
            <span class="info_name">{{ head.name }}</span>
            <span class="info_name">填报日期</span>
            <span>{{ head.date }}</span>
          </div>
          <div id="I"></div>
          <div class="form_column">
            <div class="line"></div>
            <div class="line-name">I .人口统计学及入院信息</div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">受检者姓名</div>
              <el-input size="small" style="width: 360px" v-model="name" placeholder="请输入内容"></el-input>
            </div>
            <div class="row_left">
              <div class="row_name">身份证号</div>
              <el-input size="small" style="width: 360px" @change="verification_card_num" v-model="card_num"
                        placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">登记号</div>
              <el-input size="small" style="width: 360px" v-model="reg_num" placeholder="请输入内容"></el-input>
            </div>
            <div class="row_left">
              <div class="row_name">门诊号</div>
              <el-input size="small" style="width: 360px" v-model="op_num" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">住院号</div>
              <el-input size="small" style="width: 360px" v-model="ip_num" placeholder="请输入内容"></el-input>
            </div>
            <div class="row_left">
              <div class="row_name">急诊号</div>
              <el-input size="small" style="width: 360px" v-model="ep_num" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">样本编号(可输入多个，用逗号分割)</div>
              <el-input size="small" style="width: 360px" v-model="sample_num" placeholder="请输入内容"></el-input>
            </div>
            <div class="row_left">
              <div class="row_name">出生日期</div>
              <el-date-picker
                  v-model="birthday"
                  size="small" style="width: 360px"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">长期居住地址</div>
              <el-input size="small" style="width: 360px" v-model="address" placeholder="请输入内容"></el-input>
            </div>
            <div class="row_left">
              <div class="row_name">电话号码</div>
              <el-input size="small" style="width: 360px" v-model="tel" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">入院时年龄</div>
              <el-input size="small" style="width: 360px" v-model="age" placeholder="请输入内容">
                <template slot="append">岁</template>
              </el-input>
            </div>
            <div class="row_left">
              <div class="row_name">性别</div>
              <el-select v-model="gender" size="small" style="width: 360px" placeholder="请选择">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">体重(保留一位小数)</div>
              <el-input size="small" style="width: 360px" v-model="weight" @input="changeNum" placeholder="请输入内容">
                <template slot="append">kg</template>
              </el-input>
            </div>
            <div class="row_left">
              <div class="row_name">身高(保留两位小数)</div>
              <el-input size="small" style="width: 360px" v-model="height" @input="changeNum" placeholder="请输入内容">
                <template slot="append">m</template>
              </el-input>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">体重指数</div>
              <el-input size="small" :disabled="true" style="width: 360px" v-model="mass" placeholder="请输入内容">
                <template slot="append">kg/m</template>
              </el-input>
            </div>
            <div class="row_left">
              <div class="row_name">种族</div>
              <el-radio-group v-model="race">
                <el-radio label="黄种人">黄种人</el-radio>
                <el-radio label="白种人">白种人</el-radio>
                <el-radio label="黑种人">黑种人</el-radio>
                <el-radio label="棕种人">棕种人</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">民族</div>
              <el-select v-model="nation" size="small" style="width: 360px" placeholder="请选择">
                <el-option
                    v-for="item in nation_list"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="row_left">
              <div class="row_name">是否具有家族遗传病史</div>
              <el-radio-group v-model="inherit">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
              <el-input size="small" style="width: 220px;margin-left:30px;" v-model="inherit_remark"
                        placeholder="请具体说明患何种家族遗传病"></el-input>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">是否具有膝关节外侧盘状半月板家族聚集现象</div>
              <el-radio-group style="width: 360px;" v-model="gather">
                <el-radio :label="1">无</el-radio>
                <el-radio :label="2">有(请上传家系遗传图)</el-radio>
              </el-radio-group>
              <el-upload
                  style="margin-top:20px"
                  action="https://wcb-wenjuan.mini.bandaochina.com/api/Config/uploads"
                  list-type="picture-card"
                  :file-list="gather_list_a"
                  :auto-upload=true
                  :on-success="handlePicture">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div class="row_left">
              <div class="row_name">日常生活及工作强度</div>
              <el-radio-group style="width:360px;" v-model="levels">
                <el-radio :label="0">0级</el-radio>
                <el-radio :label="1">1级</el-radio>
                <el-radio :label="2">2级</el-radio>
                <el-radio :label="3">3级</el-radio>
                <el-radio :label="4">4级</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">膝关节有无明确受伤病史</div>
              <el-radio-group v-model="knee_join">
                <el-radio :label="2">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
              <el-input size="small" style="width: 220px;margin-left:30px;" v-model="knee_join_remark"
                        placeholder="请注明具体因何种方式受伤"></el-input>
            </div>
            <div class="row_left">
              <div class="row_name">出现临床症状的关节侧别</div>
              <el-radio-group style="width: 360px" v-model="joint_side">
                <el-radio :label="0">无</el-radio>
                <el-radio :label="1">左</el-radio>
                <el-radio :label="2">右</el-radio>
                <el-radio :label="3">双膝</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">首次出现临床症状时年龄</div>
              左膝
              <el-input size="small" style="width: 150px" v-model="first_year_left_knee" placeholder="请输入内容">
                <template slot="append">岁</template>
              </el-input>
              &nbsp;
              右膝
              <el-input size="small" style="width: 150px" v-model="first_year_right_knee" placeholder="请输入内容">
                <template slot="append">岁</template>
              </el-input>
            </div>
            <div class="row_left">
              <div class="row_name">症状持续时间</div>
              左膝
              <el-input size="small" style="width: 150px" v-model="last_time_left_knee" placeholder="请输入内容">
                <template slot="append">月</template>
              </el-input>
              &nbsp;
              右膝
              <el-input size="small" style="width: 150px" v-model="last_time_right_knee" placeholder="请输入内容">
                <template slot="append">月</template>
              </el-input>
            </div>
          </div>
          <div class="form_row">
            <div class="row_left">
              <div class="row_name">临床症状的主要表现</div>
              <el-checkbox-group style="width: 440px;" v-model="later_joint">
                <el-checkbox style="margin-top: 8px" label="跛行">跛行</el-checkbox>
                <el-checkbox style="margin-top: 8px" label="交锁">交锁</el-checkbox>
                <el-checkbox style="margin-top: 8px" label="别卡">别卡</el-checkbox>
                <el-checkbox style="margin-top: 8px" label="打软腿">打软腿</el-checkbox>
                <el-checkbox style="margin-top: 8px" label="疼痛">疼痛</el-checkbox>
                <el-checkbox style="margin-top: 8px" label="弹响">弹响</el-checkbox>
                <el-checkbox style="margin-top: 8px" label="肿胀">肿胀</el-checkbox>
                <el-checkbox style="margin-top: 8px" label="上楼梯困难">上楼梯困难</el-checkbox>
                <el-checkbox style="margin-top: 8px" label="下蹲困难">下蹲困难</el-checkbox>
                <el-checkbox label="其他症状">其他症状
                  <el-input size="small" style="margin-left: 20px" v-model="other_later_joint"
                            placeholder="请输入其他症状"></el-input>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div id="II"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">II.实验室检查结果</div>
          </div>
          <table id="customers">
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name">血红蛋白：</span>
                  <el-input size="small" style="width: 90px" v-model="blood" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">白细胞：</span>
                  <el-input size="small" style="width: 90px" v-model="white_cells" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">中性粒细胞：</span>
                  <el-input size="small" style="width: 90px" v-model="neutrophil" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">中性粒细胞%：</span>
                  <el-input size="small" style="width: 90px" v-model="neutrophil_percent" placeholder="请输入"></el-input>
                </div>
              </td>
            </tr>
            <tr class="alt">
              <td>
                <div class="table_box">
                  <span class="table_name">血小板：</span>
                  <el-input size="small" style="width: 90px" v-model="platelet" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">白蛋白：</span>
                  <el-input size="small" style="width: 90px" v-model="albumin" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">谷草转氨酶：</span>
                  <el-input size="small" style="width: 90px" v-model="aspartate" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">谷丙转氨酶：</span>
                  <el-input size="small" style="width: 90px" v-model="alanine" placeholder="请输入"></el-input>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name">直接胆红素：</span>
                  <el-input size="small" style="width: 90px" v-model="bilirubin" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">间接胆红素：</span>
                  <el-input size="small" style="width: 90px" v-model="indirect" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">空腹血糖：</span>
                  <el-input size="small" style="width: 90px" v-model="glucose" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">尿素氮：</span>
                  <el-input size="small" style="width: 90px" v-model="nitrogen" placeholder="请输入"></el-input>
                </div>
              </td>
            </tr>
            <tr class="alt">
              <td>
                <div class="table_box">
                  <span class="table_name">肌酐：</span>
                  <el-input size="small" style="width: 90px" v-model="creatinine" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">血尿酸：</span>
                  <el-input size="small" style="width: 90px" v-model="uric_acid" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">K+：</span>
                  <el-input size="small" style="width: 90px" v-model="k_jia" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">Na+：</span>
                  <el-input size="small" style="width: 90px" v-model="Na_jia" placeholder="请输入"></el-input>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name">类风湿因子：</span>
                  <el-input size="small" style="width: 90px" v-model="rheumatoid" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">抗链球菌溶血素：</span>
                  <el-input size="small" style="width: 90px" v-model="anti_streptolysin" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">C反应蛋白：</span>
                  <el-input size="small" style="width: 90px" v-model="reactive_protein" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">血沉：</span>
                  <el-input size="small" style="width: 90px" v-model="esr" placeholder="请输入"></el-input>
                </div>
              </td>
            </tr>
            <tr class="alt">
              <td>
                <span class="table_name" style="width: 100%;">其他特殊实验室检测结果：</span></td>
              <td colspan="3">
                <el-input size="small" v-model="special_result" placeholder="请输入"></el-input>
              </td>
            </tr>
          </table>
          <div id="III"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">III.影像学检查结果</div>
          </div>
          <div class="ying">
            <div class="ying_round"></div>
            <div class="ying_name">膝关节X线片</div>
          </div>
          <div class="form_row">
            <div class="ying_form_name">骨关节炎K-L 分级:</div>
            <el-radio-group style="width:360px;" v-model="joint_level">
              <el-radio :label="0">0级</el-radio>
              <el-radio :label="1">1级</el-radio>
              <el-radio :label="2">2级</el-radio>
              <el-radio :label="3">3级</el-radio>
              <el-radio :label="4">4级</el-radio>
            </el-radio-group>
          </div>
          <div class="ying_img_box">
            <img class="ying_img" src="../assets/images/u1074.png" alt="">
            <img class="ying_img" src="../assets/images/u1075.png" alt="">
          </div>
          <div class="ying">
            <div class="row_name">A：</div>
            <el-input size="small" style="width: 140px;margin-right: 20px;" v-model="level_A" placeholder="请输入">
              <template slot="append">mm</template>
            </el-input>
            <div class="row_name">B：</div>
            <el-input size="small" style="width: 140px;margin-right: 20px;" type="number" v-model="level_B"
                      placeholder="请输入">
              <template slot="append">mm</template>
            </el-input>
            <div class="row_name">C：</div>
            <el-input size="small" style="width: 140px;margin-right: 20px;" v-model="level_C" type="number"
                      placeholder="请输入">
              <template slot="append">mm</template>
            </el-input>
            <div class="row_name">D：</div>
            <el-input size="small" style="width: 140px" type="number" v-model="level_D" placeholder="请输入">
              <template slot="append">度数</template>
            </el-input>
          </div>
          <div class="ying_form_name" style="margin-top: 20px">膝关节X线图片:</div>
          <el-upload
              style="margin-top:20px"
              action="https://wcb-wenjuan.mini.bandaochina.com/api/Config/uploads"
              list-type="picture-card"
              :file-list="joint_list"
              :auto-upload=true
              :on-success="handleXLine">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="ying">
            <div class="ying_round"></div>
            <div class="ying_name">膝关节核磁共振</div>
          </div>
          <div class="question">
            <div class="question_name">是否是膝关节外侧盘状半月板（如果是，请继续填报下列信息）</div>
            <el-radio-group style="width:360px;" v-model="nuclear">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板类型</div>
            <el-radio-group style="width:360px;" v-model="joint_knee">
              <el-radio label="完全型">完全型</el-radio>
              <el-radio label="不完全型">不完全型</el-radio>
              <el-radio label="Wrisberg 变异型">Wrisberg 变异型</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板是否撕裂（如果是，请继续填报下列信息）</div>
            <el-radio-group style="width:360px;" v-model="is_tear">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板撕裂类型</div>
            <el-checkbox-group style="width: 440px;" v-model="slit_type">
              <el-checkbox label="纵行和桶柄样撕裂">纵行和桶柄样撕裂</el-checkbox>
              <el-checkbox label="方射状/横向撕裂">方射状/横向撕裂</el-checkbox>
              <el-checkbox label="斜形撕裂">斜形撕裂</el-checkbox>
              <el-checkbox label="水平撕裂">水平撕裂</el-checkbox>
              <el-checkbox label="变异撕裂（包括瓣状、复合、退性性变撕裂）">变异撕裂（包括瓣状、复合、退性性变撕裂）</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板撕裂部位</div>
            <el-checkbox-group style="width: 440px;" v-model="tear_site">
              <el-checkbox label="前角">前角</el-checkbox>
              <el-checkbox label="后角">后角</el-checkbox>
              <el-checkbox label="体部">体部</el-checkbox>
              <el-checkbox label="其他">其他
                <el-input size="small" style="margin-left: 20px" v-model="other_tear_site"
                          placeholder="请输入其他情况"></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板及损伤情况磁共振图片</div>
            <el-upload
                style="margin-top:20px"
                handleInjury
                action="https://wcb-wenjuan.mini.bandaochina.com/api/Config/uploads"
                list-type="picture-card"
                :file-list="magnetic_list"
                :auto-upload=true
                :on-success="handleMagnetic">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="question">
            <div class="question_name">膝关节是否具有关节软骨损伤（如果是，请继续填报下列信息）</div>
            <el-radio-group style="width:360px;" v-model="injury">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节软骨损伤发生部位</div>
            <el-checkbox-group style="width: 440px;" v-model="cartilage_side">
              <el-checkbox style="margin-bottom:8px;" label="内侧股骨髁">内侧股骨髁</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="外侧股骨髁">外侧股骨髁</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="髌骨关节面">髌骨关节面</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="髌骨滑车关节面">髌骨滑车关节面</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="内侧胫骨平台">内侧胫骨平台</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="外侧胫骨平台">外侧胫骨平台</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节磁共振软骨损伤分级</div>
            <el-radio-group style="width:360px;" v-model="injury_level">
              <el-radio :label="0">0级</el-radio>
              <el-radio :label="1">1级</el-radio>
              <el-radio :label="2">2级</el-radio>
              <el-radio :label="3">3级</el-radio>
              <el-radio :label="4">4级</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节软骨损伤情况磁共振图片</div>
            <el-upload
                style="margin-top:20px"
                action="https://wcb-wenjuan.mini.bandaochina.com/api/Config/uploads"
                list-type="picture-card"
                :file-list="injury_list"
                :auto-upload=true
                :on-success="handleInjury">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="ying">
            <div class="ying_round"></div>
            <div class="ying_name">彩色多普勒</div>
          </div>
          <div class="question">
            <div class="question_name">是否是膝关节外侧盘状半月板（如果是，请继续填报下列信息）</div>
            <el-radio-group style="width:360px;" v-model="meniscus">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板类型</div>
            <el-radio-group style="width:360px;" v-model="lateral_discoid">
              <el-radio label="完全型">完全型</el-radio>
              <el-radio label="不完全型">不完全型</el-radio>
              <el-radio label="Wrisberg 变异型">Wrisberg 变异型</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板是否撕裂（如果是，请继续填报下列信息）</div>
            <el-radio-group style="width:360px;" v-model="meniscus_tear">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板撕裂类型</div>
            <el-checkbox-group style="width: 440px;" v-model="doppler_slit_type">
              <el-checkbox label="纵行和桶柄样撕裂">纵行和桶柄样撕裂</el-checkbox>
              <el-checkbox label="方射状/横向撕裂">方射状/横向撕裂</el-checkbox>
              <el-checkbox label="斜形撕裂">斜形撕裂</el-checkbox>
              <el-checkbox label="水平撕裂">水平撕裂</el-checkbox>
              <el-checkbox label="变异撕裂（包括瓣状、复合、退性性变撕裂）">变异撕裂（包括瓣状、复合、退性性变撕裂）</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板撕裂部位</div>
            <el-checkbox-group style="width: 440px;" v-model="tear_meniscus">
              <el-checkbox label="前角">前角</el-checkbox>
              <el-checkbox label="后角">后角</el-checkbox>
              <el-checkbox label="体部">体部</el-checkbox>
              <el-checkbox label="其他">其他
                <el-input size="small" style="margin-left: 20px" v-model="other_tear_meniscus"
                          placeholder="请输入其他情况"></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="question">
            <div class="question_name">彩超检查外侧盘状半月板图片</div>
            <el-upload
                style="margin-top:20px"
                action="https://wcb-wenjuan.mini.bandaochina.com/api/Config/uploads"
                list-type="picture-card"
                :file-list="doppler_list"
                :auto-upload=true
                :on-success="handleDoppler">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="question">
            <div class="question_name">彩超检查外侧盘状半月板损伤情况图片</div>
            <el-upload
                style="margin-top:20px"
                action="https://wcb-wenjuan.mini.bandaochina.com/api/Config/uploads"
                list-type="picture-card"
                :file-list="ultrasound_list"
                :auto-upload=true
                :on-success="handleUltrasound">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="question">
            <div class="question_name">术前主要诊断</div>
            <el-input
                type="textarea"
                style="width:360px;"
                :autosize="{ minRows: 4}"
                :rows="2"
                placeholder="请输入内容"
                v-model="diagnosis">
            </el-input>
          </div>
          <div class="question">
            <div class="question_name">术前合并膝关节病损</div>
            <el-checkbox-group style="width: 440px;" v-model="preoperative">
              <el-checkbox style="margin-bottom:8px;" label="膝关节周围或前后交叉韧带损伤">膝关节周围或前后交叉韧带损伤</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="关节周围骨折">关节周围骨折</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="骨关节炎">骨关节炎</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="化脓性关节炎">化脓性关节炎</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="其他膝关节疾病">其他膝关节疾病</el-checkbox>
            </el-checkbox-group>
            <el-input size="small" style="width: 360px;margin-top:10px;" v-model="other_preoperative"
                      placeholder="请输入"></el-input>
          </div>
          <div id="IV"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">IV.术前膝关节功能评分</div>
          </div>
          <table id="vas">
            <tr>
              <td>
                <div class="table_box">
                  <span class="vas_name">左</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="vas_name">右</span>
                </div>
              </td>
            </tr>
            <tr class="alt">
              <td>
                <div class="table_box">
                  <span class="table_name_vas">VAS 评分 (总分10，只取整数)：</span>
                  <el-input size="small" style="width: 153px" v-model="vas_left" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">VAS 评分 (总分10，只取整数)：</span>
                  <el-input size="small" style="width: 153px" v-model="vas_right" placeholder="请输入"></el-input>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">Lysholm 评分(总分100)：</span>
                  <el-input size="small" v-model="lysholm_left" style="width: 153px" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">Lysholm 评分(总分100)：</span>
                  <el-input size="small" style="width: 153px" v-model="lysholm_right" placeholder="请输入"></el-input>
                </div>
              </td>
            </tr>
            <tr class="alt">
              <td>
                <div class="table_box">
                  <span class="table_name_vas">Tegner 评分(总分10)：</span>
                  <el-input size="small" style="width: 153px" v-model="tegner_left" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">Tegner 评分(总分10)：</span>
                  <el-input size="small" style="width: 153px" v-model="tegner_right" placeholder="请输入"></el-input>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">IKDC 评分(总分83)：</span>
                  <el-input size="small" style="width: 153px" v-model="ikdc_left" placeholder="请输入"></el-input>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">IKDC 评分(总分83)：</span>
                  <el-input size="small" style="width: 153px" v-model="ikdc_right" placeholder="请输入"></el-input>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name_vas" style="width: 120px;">Ikeuchi 分级：</span>
                  <el-radio-group style="width:250px" v-model="lkeuchi_left">
                    <el-radio :label="1">优</el-radio>
                    <el-radio :label="2">良</el-radio>
                    <el-radio :label="3">中</el-radio>
                    <el-radio :label="4">差</el-radio>
                  </el-radio-group>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name_vas" style="width: 120px;">Ikeuchi 分级：</span>
                  <el-radio-group style="width:250px" v-model="lkeuchi_right">
                    <el-radio :label="1">优</el-radio>
                    <el-radio :label="2">良</el-radio>
                    <el-radio :label="3">中</el-radio>
                    <el-radio :label="4">差</el-radio>
                  </el-radio-group>
                </div>
              </td>
            </tr>
          </table>
          <div id="V"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">V.手术时年龄</div>
          </div>
          <el-input size="small" style="width: 360px;margin-top: 20px;" type="number" v-model="operation_age"
                    placeholder="请输入">
            <template slot="append">岁</template>
          </el-input>
          <div id="VI"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">VI.治疗方式</div>
          </div>
          <el-checkbox-group style="width: 440px;margin-top: 20px" v-model="treatment">
            <div>
              <el-checkbox style="margin-bottom:8px;" label="非手术治疗">非手术治疗</el-checkbox>
            </div>
            <div>
              <el-checkbox style="margin-bottom:8px;" label="外侧盘状半月板修整成形术">外侧盘状半月板修整成形术</el-checkbox>
            </div>
            <div>
              <el-checkbox style="margin-bottom:8px;" label="外侧盘状半月板修整成形+缝合修复术">外侧盘状半月板修整成形+缝合修复术</el-checkbox>
            </div>
            <div>
              <el-checkbox style="margin-bottom:8px;" label="外侧盘状半月板部分切除术">外侧盘状半月板部分切除术</el-checkbox>
            </div>
            <div>
              <el-checkbox style="margin-bottom:8px;" label="外侧盘状半月板完全切除术">外侧盘状半月板完全切除术</el-checkbox>
            </div>
          </el-checkbox-group>
          <div class="question">
            <div class="question_name">关节镜下手术治疗后整体观</div>
            <el-upload
                style="margin-top:20px"
                action="https://wcb-wenjuan.mini.bandaochina.com/api/Config/uploads"
                list-type="picture-card"
                :file-list="treatment_list"
                :auto-upload=true
                :on-success="handleTreatment">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div id="VII"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">VII.关节镜所见</div>
          </div>
          <div class="question">
            <div class="question_name">是否是膝关节外侧盘状半月板（如果是，请继续填报下列信息）</div>
            <el-radio-group style="width:360px;" v-model="is_meniscus">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板类型</div>
            <el-radio-group style="width:360px;" v-model="meniscus_type">
              <el-radio label="完全型">完全型</el-radio>
              <el-radio label="不完全型">不完全型</el-radio>
              <el-radio label="Wrisberg 变异型">Wrisberg 变异型</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">镜下盘状半月板整体观图片</div>
            <el-upload
                style="margin-top:20px"
                action="https://wcb-wenjuan.mini.bandaochina.com/api/Config/uploads"
                list-type="picture-card"
                :file-list="panoramic_list"
                :auto-upload=true
                :on-success="handlePanoramic">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板是否撕裂（如果是，请继续填报下列信息）</div>
            <el-radio-group style="width:360px;" v-model="is_meniscus_tear">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节外侧盘状半月板撕裂部位</div>
            <el-checkbox-group style="width: 440px;" v-model="meniscus_joint">
              <el-checkbox label="前角">前角</el-checkbox>
              <el-checkbox label="后角">后角</el-checkbox>
              <el-checkbox label="体部">体部</el-checkbox>
              <el-checkbox label="其他">其他
                <el-input size="small" style="margin-left: 20px" v-model="other_meniscus_joint"
                          placeholder="请输入其他情况"></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="question">
            <div class="question_name">外侧半月板损伤O'Connor 分型</div>
            <el-checkbox-group style="width: 440px;" v-model="connor">
              <el-checkbox style="margin-bottom:8px;" label="纵行或桶柄样撕裂">纵行或桶柄样撕裂</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="方射状/横向撕裂">方射状/横向撕裂</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="斜形撕裂">斜形撕裂</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="水平撕裂">水平撕裂</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="变异撕裂（包括瓣状、 复合、退性性变撕裂）">变异撕裂（包括瓣状、 复合、退性性变撕裂）</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="question">
            <div class="question_name">盘状半月板损伤情况图片</div>
            <el-upload
                style="margin-top:20px"
                action="https://wcb-wenjuan.mini.bandaochina.com/api/Config/uploads"
                list-type="picture-card"
                :file-list="gather_list"
                :auto-upload=true
                :on-success="handleMeniscus">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="question">
            <div class="question_name">膝关节是否具有关节软骨损伤（如果是，请继续填报下列信息）</div>
            <el-radio-group style="width:360px;" v-model="is_cartilage_damage">
              <el-radio :label="1">否</el-radio>
              膝关节内侧盘状半月板撕裂部位      <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节软骨损伤发生部位</div>
            <el-checkbox-group style="width: 440px;" v-model="clinical_symptoms">
              <el-checkbox style="margin-bottom:8px;" label="内侧股骨髁">内侧股骨髁</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="外侧股骨髁">外侧股骨髁</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="髌骨关节面">髌骨关节面</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="髌骨滑车关节面">髌骨滑车关节面</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="内侧胫骨平台">内侧胫骨平台</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="外侧胫骨平台">外侧胫骨平台</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="question">
            <div class="question_name">关节镜下Outerbridge软骨损伤分级</div>
            <el-radio-group style="width:360px;" v-model="outerbridge_level">
              <el-radio :label="0">0级</el-radio>
              <el-radio :label="1">1级</el-radio>
              <el-radio :label="2">2级</el-radio>
              <el-radio :label="3">3级</el-radio>
              <el-radio :label="4">4级</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">关节镜下ICRS软骨损伤分级:</div>
            <el-radio-group style="width:360px;" v-model="lcrs_level">
              <el-radio :label="0">0级</el-radio>
              <el-radio :label="1">1级</el-radio>
              <el-radio :label="2">2级</el-radio>
              <el-radio :label="3">3级</el-radio>
              <el-radio :label="4">4级</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节软骨损伤情况图片</div>
            <el-upload
                style="margin-top:20px"
                action="https://wcb-wenjuan.mini.bandaochina.com/api/Config/uploads"
                list-type="picture-card"
                :file-list="cartilage_list"
                :auto-upload=true
                :on-success="handleCartilage">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="question">
            <div class="question_name">内侧半月板是否为盘状半月板</div>
            <el-radio-group style="width:360px;" v-model="is_meniscus_discoid">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">内侧半月板是否撕裂（如果是，请继续填报下列信息）</div>
            <el-radio-group style="width:360px;" v-model="is_meniscus_torn">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </div>
          <div class="question">
            <div class="question_name">膝关节内侧盘状半月板撕裂部位</div>
            <el-checkbox-group style="width: 440px;" v-model="medial_discoid_meniscus">
              <el-checkbox label="前角">前角</el-checkbox>
              <el-checkbox label="后角">后角</el-checkbox>
              <el-checkbox label="体部">体部</el-checkbox>
              <el-checkbox label="其他">其他
                <el-input size="small" style="margin-left: 20px" v-model="other_medial_discoid_meniscus"
                          placeholder="请输入其他情况"></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="question">
            <div class="question_name">内侧半月板损伤O'Connor 分型</div>
            <el-checkbox-group style="width: 440px;" v-model="inside_connor">
              <el-checkbox style="margin-bottom:8px;" label="纵行或桶柄样撕裂">纵行或桶柄样撕裂</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="方射状/横向撕裂">方射状/横向撕裂</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="斜形撕裂">斜形撕裂</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="水平撕裂">水平撕裂</el-checkbox>
              <el-checkbox style="margin-bottom:8px;" label="变异撕裂（包括瓣状、 复合、退性性变撕裂）">变异撕裂（包括瓣状、 复合、退性性变撕裂）</el-checkbox>
            </el-checkbox-group>
          </div>
          <div id="VIII"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">VIII.术后并发症</div>
          </div>
          <el-checkbox-group style="margin-top: 30px" v-model="operation_complications">
            <el-checkbox style="margin-bottom:8px;width: 25%" label="无并发症">无并发症</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="膝关节疼痛">膝关节疼痛</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="膝关节活动障碍">膝关节活动障碍</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="切口感染">切口感染</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="切口愈合不良">切口愈合不良</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="关节腔内感染">关节腔内感染</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="术后关节腔出血">术后关节腔出血</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="下肢深静脉血栓">下肢深静脉血栓</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="肺栓塞">肺栓塞</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="压疮">压疮</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="肺炎">肺炎</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="泌尿系感染">泌尿系感染</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 25%" label="半月板再破裂">半月板再破裂</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 35%" label="其他情况">其他情况
              <el-input size="small" style="margin-left: 20px" v-model="other_complications"
                        placeholder="请输入其他情况"></el-input>
            </el-checkbox>
          </el-checkbox-group>
          <div id="IX"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">IX.术后负重时间</div>
          </div>
          <el-input size="small" style="width: 360px;margin-top: 20px;" v-model="bearing_time" placeholder="请输入">
            <template slot="append">月</template>
          </el-input>
          <div id="X"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">X.术后是否进行规范化、系统性功能锻炼</div>
          </div>
          <el-radio-group style="margin: 30px 0" v-model="is_exercise">
            <el-radio :label="1">否</el-radio>
            <el-radio :label="2">是</el-radio>
          </el-radio-group>
          <div class="follow" v-for="(item,index) in follow_data" :key="index">
            <div class="follow_lan">
              <div class="follow_name">术后随访时间</div>
              <div class="shan" v-if="follow_data.length > 1" @click="deleteFollow(index)">删除</div>
            </div>
            <el-date-picker
                v-model="item.follow_time"
                size="small"
                style="width: 435px;margin:14px 0;"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
            <div class="form_column">
              <div class="line"></div>
              <div class="line-name">XI.术后疗效评估</div>
            </div>
            <table id="vas">
              <tr>
                <td>
                  <div class="table_box">
                    <span class="vas_name">左</span>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="vas_name">右</span>
                  </div>
                </td>
              </tr>
              <tr class="alt">
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">VAS 评分 (总分10，只取整数)：</span>
                    <el-input size="small" style="width: 153px" type="number" v-model="item.follow_vas_left"
                              placeholder="请输入"></el-input>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">VAS 评分 (总分10，只取整数)：</span>
                    <el-input size="small" style="width: 153px" type="number" v-model="item.follow_vas_right"
                              placeholder="请输入"></el-input>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">Lysholm 评分(总分100)：</span>
                    <el-input size="small" type="number" v-model="item.follow_lysholm_left" style="width: 153px"
                              placeholder="请输入"></el-input>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">Lysholm 评分(总分100)：</span>
                    <el-input size="small" style="width: 153px" type="number" v-model="item.follow_lysholm_right"
                              placeholder="请输入"></el-input>
                  </div>
                </td>
              </tr>
              <tr class="alt">
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">Tegner 评分(总分10)：</span>
                    <el-input size="small" style="width: 153px" type="number" v-model="item.follow_tegner_left"
                              placeholder="请输入"></el-input>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">Tegner 评分(总分10)：</span>
                    <el-input size="small" style="width: 153px" type="number" v-model="item.follow_tegner_right"
                              placeholder="请输入"></el-input>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">IKDC 评分(总分83)：</span>
                    <el-input size="small" style="width: 153px" type="number" v-model="item.follow_lkdc_left"
                              placeholder="请输入"></el-input>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">IKDC 评分(总分83)：</span>
                    <el-input size="small" style="width: 153px" type="number" v-model="item.follow_lkdc_right"
                              placeholder="请输入"></el-input>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas" style="width: 105px;">Ikeuchi 分级：</span>
                    <el-radio-group style="width:250px" v-model="item.follow_lkeuchi_left">
                      <el-radio :label="1">优</el-radio>
                      <el-radio :label="2">良</el-radio>
                      <el-radio :label="3">中</el-radio>
                      <el-radio :label="4">差</el-radio>
                    </el-radio-group>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas" style="width: 105px;">Ikeuchi 分级：</span>
                    <el-radio-group style="width:250px" v-model="item.follow_lkeuchi_right">
                      <el-radio :label="1">优</el-radio>
                      <el-radio :label="2">良</el-radio>
                      <el-radio :label="3">中</el-radio>
                      <el-radio :label="4">差</el-radio>
                    </el-radio-group>
                  </div>
                </td>
              </tr>
            </table>
            <div class="form_column" style="margin-top:30px">
              <div class="line"></div>
              <div class="line-name">XII.随访期间对膝关节不适的处理措施</div>
            </div>
            <el-checkbox-group style="margin-top: 30px" v-model="item.treatment_measures">
              <el-checkbox style="margin-bottom:8px;width: 30%" label="无不适">无不适</el-checkbox>
              <el-checkbox style="margin-bottom:8px;width: 30%" label="心理疏导、指导康复锻炼，未行药物干预">心理疏导、指导康复锻炼，未行药物干预
              </el-checkbox>
              <el-checkbox style="margin-bottom:8px;width: 30%" label="对症性药物干预">对症性药物干预</el-checkbox>
              <el-checkbox style="margin-bottom:8px;width: 20%" label="再次手术">再次手术</el-checkbox>
              <el-checkbox style="margin-bottom:8px;width: 35%" label="其他">其他
                <el-input size="small" style="margin-left: 20px" v-model="item.other_treatment_measures"
                          placeholder="请输入其他情况"></el-input>
              </el-checkbox>
            </el-checkbox-group>
            <div class="form_column" style="margin-top:30px">
              <div class="line"></div>
              <div class="line-name">XIII.其他需要特殊说明的情况</div>
            </div>
            <el-input
                type="textarea"
                style="margin-top:10px"
                :autosize="{ minRows: 4}"
                :rows="2"
                placeholder="请输入内容"
                v-model="item.remark_special">
            </el-input>
          </div>
          <div class="follow_btn" @click="add">+随机访问</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "form",
  data() {
    return {
      cate_id: '',
      options: [{
        value: 1,
        label: '男'
      }, {
        value: 2,
        label: '女'
      }],
      int: 0,
      name: '',
      card_num: '',
      reg_num: '',
      later_joint: [],
      other_later_joint: '',
      ip_num: '',
      op_num: '',
      ep_num: '',
      sample_num: '',
      birthday: '',
      address: '',
      tel: '',
      age: '',
      gender: 1,
      weight: '',
      height: '',
      mass: '',
      race: '黄种人',
      nation_list: [],
      nation: '',
      inherit: 1,
      inherit_remark: '',
      gather: 1,
      gather_list: [],
      gather_list_a: [],
      gather_images: [],
      magnetic_list: [],
      injury_list: [],
      joint_list: [],
      doppler_list: [],
      ultrasound_list: [],
      treatment_list: [],
      panoramic_list: [],
      cartilage_list: [],
      levels: 0,
      knee_join: 1,
      knee_join_remark: '',
      joint_side: 0,
      first_year_left_knee: '',
      first_year_right_knee: '',
      last_time_left_knee: '',
      last_time_right_knee: '',
      blood: '',
      white_cells: '',
      neutrophil: '',
      neutrophil_percent: '',
      platelet: '',
      albumin: '',
      aspartate: '',
      alanine: '',
      bilirubin: '',
      indirect: '',
      glucose: '',
      nitrogen: '',
      creatinine: '',
      uric_acid: '',
      k_jia: '',
      Na_jia: '',
      rheumatoid: '',
      anti_streptolysin: '',
      reactive_protein: '',
      esr: '',
      special_result: '',
      joint_level: 0,
      level_A: '',
      level_B: '',
      level_C: '',
      level_D: '',
      joint_images: [],
      nuclear: 1,
      slit_type: [],
      joint_knee: '',
      is_tear: 1,
      tear_site: [],
      other_tear_site: '',
      magnetic_images: [],
      injury: 1,
      cartilage_side: [],
      injury_level: 0,
      injury_images: [],
      meniscus: 1,
      lateral_discoid: [],
      meniscus_tear: 1,
      doppler_slit_type: [],
      tear_meniscus: [],
      other_tear_meniscus: '',
      doppler_ultrasound: [],
      ultrasound_examination: [],
      diagnosis: '',
      preoperative: [],
      other_preoperative: '',
      vas_left: '',
      lysholm_left: '',
      tegner_left: '',
      ikdc_left: '',
      lkeuchi_left: 1,
      vas_right: '',
      lysholm_right: '',
      tegner_right: '',
      ikdc_right: '',
      lkeuchi_right: 1,
      operation_age: '',
      treatment: [],
      treatment_images: [],
      panoramic_view_images: [],
      meniscus_injury_imgs: [],
      cartilage_injury_imgs: [],
      is_meniscus: 1,
      meniscus_type: '',
      is_meniscus_tear: 1,
      meniscus_joint: [],
      other_meniscus_joint: '',
      connor: [],
      is_cartilage_damage: 1,
      clinical_symptoms: [],
      outerbridge_level: 0,
      lcrs_level: 0,
      is_meniscus_discoid: 1,
      is_meniscus_torn: 1,
      medial_discoid_meniscus: [],
      other_medial_discoid_meniscus: '',
      inside_connor: [],
      operation_complications: [],
      other_complications: '',
      bearing_time: '',
      is_exercise: 1,
      follow_data: [
        {
          follow_time: '',
          follow_vas_left: '',
          follow_lysholm_left: '',
          follow_tegner_left: '',
          follow_lkdc_left: '',
          follow_lkeuchi_left: 1,
          follow_vas_right: '',
          follow_lysholm_right: '',
          follow_tegner_right: '',
          follow_lkdc_right: '',
          follow_lkeuchi_right: 1,
          treatment_measures: [],
          other_treatment_measures: '',
          remark_special: ''
        }
      ],
      head: ''
    }
  },
  props: {
    id: {
      type: Number
    }
  },
  methods: {
    handlePicture(file) {
      this.gather_images.push(file.data.img)
      console.log(this.gather_images)
    },
    handleXLine(file) {
      this.joint_images.push(file.data.img)
    },
    handleMagnetic(file) {
      this.magnetic_images.push(file.data.img)
    },
    handleInjury(file) {
      this.injury_images.push(file.data.img)
    },
    handleDoppler(file) {
      this.doppler_ultrasound.push(file.data.img)
    },
    handleUltrasound(file) {
      this.ultrasound_examination.push(file.data.img)
    },
    handleTreatment(file) {
      this.treatment_images.push(file.data.img)
    },
    handlePanoramic(file) {
      this.panoramic_view_images.push(file.data.img)
    },
    handleMeniscus(file) {
      this.meniscus_injury_imgs.push(file.data.img)
    },
    handleCartilage(file) {
      this.cartilage_injury_imgs.push(file.data.img)
    },
    add() {
      this.follow_data.push({
        follow_time: '',
        follow_vas_left: '',
        follow_lysholm_left: '',
        follow_tegner_left: '',
        follow_lkdc_left: '',
        follow_lkeuchi_left: 1,
        follow_vas_right: '',
        follow_lysholm_right: '',
        follow_tegner_right: '',
        follow_lkdc_right: '',
        follow_lkeuchi_right: 1,
        treatment_measures: [],
        other_treatment_measures: '',
        remark_special: ''
      })
    },
    deleteFollow(index) {
      this.follow_data.splice(index, 1)
    },
    getNation() {
      this.$request('/Config/nation_list').then((res) => {
        this.nation_list = res.data
      })
    },
    getInfo() {
      this.$request('/Member/member_info').then((res) => {
        this.head = res.data
      })
    },
    changeNum() {
      this.mass = this.weight / this.height
    },
    back() {
      this.$parent.openPage();
      if (this.id != 0) {
        this.$parent.getList();
      }
    },
    showIcon(e) {
      let height = e.target.scrollTop;
      console.log(height)
      if (height < 1614) {
        this.int = 0
      } else if (1614 <= height && height < 2115) {
        this.int = 1
      } else if (2115 <= height && height < 4594) {
        this.int = 2
      } else if (4594 <= height && height < 5033) {
        this.int = 3
      } else if (5033 <= height && height < 5167) {
        this.int = 4
      } else if (5167 <= height && height < 5543) {
        this.int = 5
      } else if (5543 <= height && height < 7049) {
        this.int = 6
      } else if (7049 <= height && height < 7313) {
        this.int = 7
      } else if (7313 <= height && height < 7445) {
        this.int = 8
      } else if (height > 7445) {
        this.int = 9
      }
    },
    verification_card_num(card_num) {
      this.$request('/Question/verification_card_num', {card_num: card_num}).then((res) => {
        console.log(res.data);
      })
    },
    getDetail() {
      if (this.id != 0) {
        this.$request('/Question/question_detail', {question_id: this.id}).then((res) => {
          this.name = res.data.name
          this.card_num = res.data.card_num
          this.reg_num = res.data.reg_num
          this.ip_num = res.data.ip_num
          this.op_num = res.data.op_num
          this.ep_num = res.data.ep_num
          this.sample_num = res.data.sample_num
          this.birthday = res.data.birthday
          this.address = res.data.address
          this.tel = res.data.tel
          this.age = res.data.age
          this.gender = res.data.gender
          this.weight = res.data.weight
          this.height = res.data.height
          this.mass = res.data.mass
          this.race = res.data.race
          this.nation = res.data.nation
          this.inherit = res.data.inherit
          this.inherit_remark = res.data.inherit_remark
          this.gather = res.data.gather
          this.gather_list_a = res.data.gather_images.map(item => ({
            url: item
          }))
          this.gather_images = res.data.gather_images
          this.levels = res.data.levels
          this.knee_join = res.data.knee_join
          this.joint_side = Number(res.data.joint_side)
          this.first_year_left_knee = res.data.first_year_left_knee
          this.first_year_right_knee = res.data.first_year_right_knee
          this.last_time_left_knee = res.data.last_time_left_knee
          this.last_time_right_knee = res.data.last_time_right_knee
          this.later_joint = res.data.later_joint
          this.other_later_joint = res.data.other_later_joint
          this.white_cells = res.data.laboratory.white_cells;
          this.blood = res.data.laboratory.blood;
          this.neutrophil = res.data.laboratory.neutrophil
          this.neutrophil_percent = res.data.laboratory.neutrophil_percent
          this.platelet = res.data.laboratory.platelet
          this.albumin = res.data.laboratory.albumin
          this.aspartate = res.data.laboratory.aspartate
          this.alanine = res.data.laboratory.alanine
          this.bilirubin = res.data.laboratory.bilirubin
          this.indirect = res.data.laboratory.indirect
          this.glucose = res.data.laboratory.glucose
          this.nitrogen = res.data.laboratory.nitrogen
          this.creatinine = res.data.laboratory.creatinine
          this.uric_acid = res.data.laboratory.uric_acid
          this.k_jia = res.data.laboratory.k_jia
          this.Na_jia = res.data.laboratory.Na_jia
          this.rheumatoid = res.data.laboratory.rheumatoid
          this.anti_streptolysin = res.data.laboratory.anti_streptolysin
          this.reactive_protein = res.data.laboratory.reactive_protein
          this.esr = res.data.laboratory.esr
          this.special_result = res.data.laboratory.special_result
          this.joint_level = res.data.esr_data.joint_level
          this.level_A = res.data.esr_data.level_A
          this.level_B = res.data.esr_data.level_B
          this.level_C = res.data.esr_data.level_C
          this.level_D = res.data.esr_data.level_D
          this.joint_images = res.data.esr_data.joint_images
          this.joint_list = res.data.esr_data.joint_images.map(item => ({
            url: item
          }))
          this.nuclear = res.data.esr_data.nuclear
          this.slit_type = res.data.esr_data.slit_type
          this.joint_knee = res.data.esr_data.joint_knee
          this.is_tear = res.data.esr_data.is_tear
          this.tear_site = res.data.esr_data.tear_site
          this.other_tear_site = res.data.esr_data.other_tear_site
          this.magnetic_images = res.data.esr_data.magnetic_images
          this.magnetic_list = res.data.esr_data.magnetic_images.map(item => ({
            url: item
          }))
          this.injury = res.data.esr_data.injury
          this.cartilage_side = res.data.esr_data.cartilage_side
          this.injury_level = res.data.esr_data.injury_level
          this.injury_images = res.data.esr_data.injury_images
          this.injury_list = res.data.esr_data.injury_images.map(item => ({
            url: item
          }))
          this.meniscus = res.data.esr_data.meniscus
          this.lateral_discoid = res.data.esr_data.lateral_discoid
          this.meniscus_tear = res.data.esr_data.meniscus_tear
          this.doppler_slit_type = res.data.esr_data.doppler_slit_type
          this.tear_meniscus = res.data.esr_data.tear_meniscus
          this.other_tear_meniscus = res.data.esr_data.other_tear_meniscus
          this.doppler_ultrasound = res.data.esr_data.doppler_ultrasound
          this.doppler_list = res.data.esr_data.doppler_ultrasound.map(item => ({
            url: item
          }))
          this.ultrasound_examination = res.data.esr_data.ultrasound_examination
          this.ultrasound_list = res.data.esr_data.ultrasound_examination.map(item => ({
            url: item
          }))
          this.diagnosis = res.data.esr_data.diagnosis
          this.preoperative = res.data.esr_data.preoperative
          this.other_preoperative = res.data.esr_data.other_preoperative
          this.vas_left = res.data.score.vas_left
          this.lysholm_left = res.data.score.lysholm_left
          this.tegner_left = res.data.score.tegner_left
          this.ikdc_left = res.data.score.ikdc_left
          this.lkeuchi_left = res.data.score.lkeuchi_left
          this.vas_right = res.data.score.vas_right
          this.lysholm_right = res.data.score.lysholm_right
          this.tegner_right = res.data.score.tegner_right
          this.ikdc_right = res.data.score.ikdc_right
          this.lkeuchi_right = res.data.score.lkeuchi_right
          this.operation_age = res.data.operation.operation_age
          this.treatment = res.data.operation.treatment
          this.treatment_images = res.data.operation.treatment_images
          this.treatment_list = res.data.operation.treatment_images.map(item => ({
            url: item
          }))
          this.is_meniscus = res.data.operation.is_meniscus
          this.meniscus_type = res.data.operation.meniscus_type
          this.panoramic_view_images = res.data.operation.panoramic_view_images
          this.panoramic_list = res.data.operation.panoramic_view_images.map(item => ({
            url: item
          }))
          this.is_meniscus_tear = res.data.operation.is_meniscus_tear
          this.meniscus_joint = res.data.operation.meniscus_joint
          this.other_meniscus_joint = res.data.operation.other_meniscus_joint
          this.connor = res.data.operation.connor
          this.meniscus_injury_imgs = res.data.operation.meniscus_injury_imgs
          this.gather_list = res.data.operation.meniscus_injury_imgs.map(item => ({
            url: item
          }))
          this.is_cartilage_damage = res.data.operation.is_cartilage_damage
          this.clinical_symptoms = res.data.operation.clinical_symptoms
          this.outerbridge_level = res.data.operation.outerbridge_level
          this.lcrs_level = res.data.operation.lcrs_level
          this.cartilage_injury_imgs = res.data.operation.cartilage_injury_imgs
          this.cartilage_list = res.data.operation.cartilage_injury_imgs.map(item => ({
            url: item
          }))
          this.is_meniscus_discoid = res.data.operation.is_meniscus_discoid
          this.is_meniscus_torn = res.data.operation.is_meniscus_torn
          this.medial_discoid_meniscus = res.data.operation.medial_discoid_meniscus
          this.other_medial_discoid_meniscus = res.data.operation.other_medial_discoid_meniscus
          this.inside_connor = res.data.operation.inside_connor
          this.operation_complications = res.data.operation.operation_complications
          this.other_complications = res.data.operation.other_complications
          this.bearing_time = res.data.operation.bearing_time
          this.is_exercise = res.data.operation.is_exercise
          this.follow_data = res.data.follow
        })
      }
    },
    submit() {
      let data = {
        cate_id: 1,
        name: this.name,
        card_num: this.card_num,
        reg_num: this.reg_num,
        ip_num: this.ip_num,
        op_num: this.op_num,
        ep_num: this.ep_num,
        sample_num: this.sample_num,
        birthday: this.birthday,
        address: this.address,
        tel: this.tel,
        age: this.age,
        gender: this.gender,
        weight: this.weight,
        height: this.height,
        mass: this.mass,
        race: this.race,
        nation: this.nation,
        inherit: this.inherit,
        inherit_remark: this.inherit_remark,
        gather: this.gather,
        gather_images: this.gather_images,
        levels: this.levels,
        knee_join: this.knee_join,
        knee_join_remark: this.knee_join_remark,
        joint_side: this.joint_side,
        first_year_left_knee: this.first_year_left_knee,
        first_year_right_knee: this.first_year_right_knee,
        last_time_left_knee: this.last_time_left_knee,
        last_time_right_knee: this.last_time_right_knee,
        later_joint: this.later_joint,
        other_later_joint: this.other_later_joint,
        blood: this.blood,
        white_cells: this.white_cells,
        neutrophil: this.neutrophil,
        neutrophil_percent: this.neutrophil_percent,
        platelet: this.platelet,
        albumin: this.albumin,
        aspartate: this.aspartate,
        alanine: this.alanine,
        bilirubin: this.bilirubin,
        indirect: this.indirect,
        glucose: this.glucose,
        nitrogen: this.nitrogen,
        creatinine: this.creatinine,
        uric_acid: this.uric_acid,
        k_jia: this.k_jia,
        Na_jia: this.Na_jia,
        rheumatoid: this.rheumatoid,
        anti_streptolysin: this.anti_streptolysin,
        reactive_protein: this.reactive_protein,
        esr: this.esr,
        special_result: this.special_result,
        joint_level: this.joint_level,
        level_A: this.level_A,
        level_B: this.level_B,
        level_C: this.level_C,
        level_D: this.level_D,
        joint_images: this.joint_images,
        nuclear: this.nuclear,
        slit_type: this.slit_type,
        joint_knee: this.joint_knee,
        is_tear: this.is_tear,
        tear_site: this.tear_site,
        other_tear_site: this.other_tear_site,
        magnetic_images: this.magnetic_images,
        injury: this.injury,
        cartilage_side: this.cartilage_side,
        injury_level: this.injury_level,
        injury_images: this.injury_images,
        meniscus: this.meniscus,
        lateral_discoid: this.lateral_discoid,
        meniscus_tear: this.meniscus_tear,
        doppler_slit_type: this.doppler_slit_type,
        tear_meniscus: this.tear_meniscus,
        other_tear_meniscus: this.other_tear_meniscus,
        doppler_ultrasound: this.doppler_ultrasound,
        ultrasound_examination: this.ultrasound_examination,
        diagnosis: this.diagnosis,
        preoperative: this.preoperative,
        other_preoperative: this.other_preoperative,
        vas_left: this.vas_left,
        lysholm_left: this.lysholm_left,
        tegner_left: this.tegner_left,
        ikdc_left: this.ikdc_left,
        lkeuchi_left: this.lkeuchi_left,
        vas_right: this.vas_right,
        lysholm_right: this.lysholm_right,
        tegner_right: this.tegner_right,
        ikdc_right: this.ikdc_right,
        lkeuchi_right: this.lkeuchi_right,
        operation_age: this.operation_age,
        treatment: this.treatment,
        treatment_images: this.treatment_images,
        is_meniscus: this.is_meniscus,
        meniscus_type: this.meniscus_type,
        panoramic_view_images: this.panoramic_view_images,
        is_meniscus_tear: this.is_meniscus_tear,
        meniscus_joint: this.meniscus_joint,
        other_meniscus_joint: this.other_meniscus_joint,
        connor: this.connor,
        meniscus_injury_imgs: this.meniscus_injury_imgs,
        is_cartilage_damage: this.is_cartilage_damage,
        clinical_symptoms: this.clinical_symptoms,
        outerbridge_level: this.outerbridge_level,
        lcrs_level: this.lcrs_level,
        cartilage_injury_imgs: this.cartilage_injury_imgs,
        is_meniscus_discoid: this.is_meniscus_discoid,
        is_meniscus_torn: this.is_meniscus_torn,
        medial_discoid_meniscus: this.medial_discoid_meniscus,
        other_medial_discoid_meniscus: this.other_medial_discoid_meniscus,
        inside_connor: this.inside_connor,
        operation_complications: this.operation_complications,
        other_complications: this.other_complications,
        bearing_time: this.bearing_time,
        is_exercise: this.is_exercise,
        follow_data: this.follow_data
      }
      if (this.id == 0) {
        this.$request('/Question/create_question', data).then((res) => {
          if (res.errcode === 0) {
            this.name = '',
                this.card_num = '',
                this.reg_num = '',
                this.later_joint = [],
                this.other_later_joint = '',
                this.ip_num = '',
                this.op_num = '',
                this.ep_num = '',
                this.sample_num = '',
                this.birthday = '',
                this.address = '',
                this.tel = '',
                this.age = '',
                this.gender = 1,
                this.weight = '',
                this.height = '',
                this.mass = '',
                this.race = '黄种人',
                this.nation_list = [],
                this.nation = '',
                this.inherit = 1,
                this.inherit_remark = '',
                this.gather = 1,
                this.gather_list = [],
                this.gather_list_a = [],
                this.gather_images = [],
                this.magnetic_list = [],
                this.injury_list = [],
                this.joint_list = [],
                this.doppler_list = [],
                this.ultrasound_list = [],
                this.treatment_list = [],
                this.panoramic_list = [],
                this.cartilage_list = [],
                this.levels = 0,
                this.knee_join = 1,
                this.knee_join_remark = '',
                this.joint_side = 0,
                this.first_year_left_knee = '',
                this.first_year_right_knee = '',
                this.last_time_left_knee = '',
                this.last_time_right_knee = '',
                this.blood = '',
                this.white_cells = '',
                this.neutrophil = '',
                this.neutrophil_percent = '',
                this.platelet = '',
                this.albumin = '',
                this.aspartate = '',
                this.alanine = '',
                this.bilirubin = '',
                this.indirect = '',
                this.glucose = '',
                this.nitrogen = '',
                this.creatinine = '',
                this.uric_acid = '',
                this.k_jia = '',
                this.Na_jia = '',
                this.rheumatoid = '',
                this.anti_streptolysin = '',
                this.reactive_protein = '',
                this.esr = '',
                this.special_result = '',
                this.joint_level = 0,
                this.level_A = '',
                this.level_B = '',
                this.level_C = '',
                this.level_D = '',
                this.joint_images = [],
                this.nuclear = 1,
                this.slit_type = [],
                this.joint_knee = '',
                this.is_tear = 1,
                this.tear_site = [],
                this.other_tear_site = '',
                this.magnetic_images = [],
                this.injury = 1,
                this.cartilage_side = [],
                this.injury_level = 0,
                this.injury_images = [],
                this.meniscus = 1,
                this.lateral_discoid = [],
                this.meniscus_tear = 1,
                this.doppler_slit_type = [],
                this.tear_meniscus = [],
                this.other_tear_meniscus = '',
                this.doppler_ultrasound = [],
                this.ultrasound_examination = [],
                this.diagnosis = '',
                this.preoperative = [],
                this.other_preoperative = '',
                this.vas_left = '',
                this.lysholm_left = '',
                this.tegner_left = '',
                this.ikdc_left = '',
                this.lkeuchi_left = 1,
                this.vas_right = '',
                this.lysholm_right = '',
                this.tegner_right = '',
                this.ikdc_right = '',
                this.lkeuchi_right = 1,
                this.operation_age = '',
                this.treatment = [],
                this.treatment_images = [],
                this.panoramic_view_images = [],
                this.panoramic_view_images = [],
                this.meniscus_injury_imgs = [],
            this.cartilage_injury_imgs = [],
            this.is_meniscus = 1,
            this.meniscus_type = '',
            this.is_meniscus_tear = 1,
            this.meniscus_joint = [],
            this.other_meniscus_joint = '',
            this.connor = [],
            this.is_cartilage_damage = 1,
            this.clinical_symptoms = [],
            this.outerbridge_level = 0,
            this.lcrs_level = 0,
            this.is_meniscus_discoid = 1,
            this.is_meniscus_torn = 1,
            this.medial_discoid_meniscus = [],
            this.other_medial_discoid_meniscus = '',
            this.inside_connor = [],
            this.operation_complications = [],
            this.other_complications = '',
            this.bearing_time = '',
            this.is_exercise = 1,
            this.follow_data = [
              {
                follow_time: '',
                follow_vas_left: '',
                follow_lysholm_left: '',
                follow_tegner_left: '',
                follow_lkdc_left: '',
                follow_lkeuchi_left: 1,
                follow_vas_right: '',
                follow_lysholm_right: '',
                follow_tegner_right: '',
                follow_lkdc_right: '',
                follow_lkeuchi_right: 1,
                treatment_measures: [],
                other_treatment_measures: '',
                remark_special: ''
              }
            ]
            this.$notify({
              title: '成功',
              message: '操作成功',
              type: 'success'
            });
          } else {
            Notification.error({
              title: '提醒',
              message: res.errmsg
            })
          }
        })
      } else {
        this.$request('Question/question_edit_post', {...data, question_id: this.id}).then((res) => {
          if (res.errcode === 0) {
            this.getDetail();
            this.$notify({
              title: '成功',
              message: '操作成功',
              type: 'success'
            });
          } else {
            Notification.error({
              title: '提醒',
              message: res.errmsg
            })
          }
        })
      }
    }
  },
  created() {
    this.getNation();
    this.getDetail();
    this.getInfo();
  }
}
</script>

<style scoped>
* {
  text-align: left;
}

.zhe {
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow-y: auto;
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

#customers td, #customers th {
  font-size: 1em;
  border: 1px solid #e4e4e4;
  padding: 3px 7px 2px 7px;
}

#customers th {
  font-size: 1.1em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #A7C942;
  color: #ffffff;
}

#customers tr td {
  color: #000000;
  background-color: #f8f8fa;
  width: 194px;
  height: 60px;
}

#vas {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

#vas td, #vas th {
  font-size: 1em;
  border: 1px solid #e4e4e4;
  padding: 3px 7px 2px 7px;
}

#vas th {
  font-size: 1.1em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #A7C942;
  color: #ffffff;
}

#vas tr td {
  color: #000000;
  background-color: #f8f8fa;
  height: 50px;
}

.form_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #e4e4e4;
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.top_left {
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  color: #343434;
  margin-left: 20px;
}

.menu_item {
  padding: 10px;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  color: #1C313A;
  cursor: pointer;
  display: block;
  text-decoration: none;
}

.menu_item:hover {
  background: #f8f8f8;
}

.menu_item_a {
  background: #f8f8f8;
}

.form_left {
  width: 180px;
  border-right: 5px solid #f0f2f5;
  padding: 20px 10px;
  position: fixed;
  left: 0;
  top: 50px;
  height: 100%;
}

.form_right {
  width: 100%;
}

.form_bottom {
  display: flex;
}

.right_center {
  width: 776px;
  margin: 0 auto;
}

.right_logo {
  width: 409px;
  height: 150px;
  display: block;
  margin: 0 auto;
}

.right_title {
  font-family: '隶书';
  margin: 10px auto;
  width: 648px;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  color: #0070C0;
}

.info {
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  color: #868686;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.form_column {
  height: 32px;
  display: flex;
  align-items: center;
  background: #f8f8fa;
}

.info_name {
  margin: 0 20px 0 20px;
}

.row_name {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1C313A;
  margin-bottom: 10px;
  margin-left: 2px;
}

.form_row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.line {
  height: 20px;
  border-left: 3px solid #267330;
  margin-right: 10px;
}

.line-name {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #3E3A39;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .avatar {
  width: 80px;
  height: 80px;
}

.table_name {
  display: block;
  width: 100px;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1C313A;
  text-align: right;
}

.table_name_vas {
  display: block;
  width: 200px;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1C313A;
  text-align: right;
}

.table_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ying {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.ying_round {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #226527;
}

.ying_name {
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: #226527;
  margin-left: 8px;
}

.ying_form_name {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1C313A;
}

.ying_img_box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.ying_img {
  width: 315px;
  height: 385px;
}

.question {
  margin-top: 20px;
}

.question_name {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1C313A;
  margin-bottom: 12px;
}

.vas_name {
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: #267330;
}

.follow {
  border: 1px dotted #d5d5d5;
  padding: 10px;
  margin-bottom: 20px;
}

.follow_name {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1C313A;
}

.follow_lan {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shan {
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  color: #FF6868;
  margin-right: 20px;
  cursor: pointer;
}

.follow_btn {
  width: 776px;
  height: 35px;
  border: 1px dotted #d5d5d5;
  text-align: center;
  line-height: 35px;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  color: #267330;
  margin-top: 20px;
  border-radius: 4px;
  margin-bottom: 60px;
  cursor: pointer;
}
</style>
