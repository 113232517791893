<template>
    <div class="main">
        <div class="main_center">
            <div class="center_top">
                <span class="date_name">创建日期</span>
                <el-date-picker
                        v-model="value"
                        type="daterange"
                        size="small"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        @change="changeDate"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
                <el-button class="btn" style="margin-left: 20px" size="small" type="primary" @click="getList">查询</el-button>
                <el-button class="btn" size="small" @click="chong">重置</el-button>
            </div>
            <div class="item_list">
                <div class="item" v-for="item in list" :key="item.id">
                    <div class="item_top">
                        <img class="item_logo" :src="item.img" alt="">
                        <div style="margin-left:10px;">
                            <div class="item_name">{{item.cate_title}}</div>
                            <div class="item_time">创建时间：{{item.create_date}}</div>
                        </div>
                    </div>
                    <el-button class="add" type="primary" v-if="item.add_type == 1" @click="openPage">新建问卷</el-button>
                    <el-button class="add" type="info" v-else>新建问卷</el-button>
                </div>
            </div>
        </div>
        <page :id="0" v-if="visible"></page>
    </div>
</template>

<script>
    import page from './form_page'
    export default {
        name: "index",
        components:{ page },
        data() {
            return {
                value:'',
                start_time:'',
                end_time:'',
                list:[],
                visible:false,
                info:''
            }
        },
        methods:{
            changeDate() {
               this.start_time = this.value[0];
                this.end_time = this.value[1]
            },
            chong() {
              this.value = ''
            },
            getList() {
                this.$request('/Question/question_cate',{start_time:this.start_time,end_time:this.end_time}).then( (res) => {
                    this.list = res.data
                })
            },
            getInfo() {
                this.$request('/Member/member_info').then( (res) => {
                    this.info = res.data
                })
            },
            openPage() {
                this.visible = !this.visible
            }
        },
        created() {
            this.getList();
            this.getInfo();
        }
    }
</script>

<style scoped lang="scss">
    .main {
        width: 100%;
        height: 92vh;
        background: url("../assets/images/u295_state0.png");
        background-size: 100% 100%;
    }
    .main_center {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
        .center_top {
            display: flex;
            margin-top: 20px;
            align-items: center;
        }
        .date_name {
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            color: #325562;
            margin-right: 20px;
        }
    }
    .item {
        width: 252px;
        border: 1px solid #267330;
        border-radius: 6px;
        padding: 16px;
        margin: 0 7px;
        margin-top: 20px;
    }
    .item_top {
        display: flex;
        justify-content: space-between;
        .item_logo {
            width: 50px;
            height: 50px;
            flex: 0 0 auto;
        }
        .item_name {
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            color: #272727;
            text-align: left;
        }
        .item_time {
            font-size: 12px;
            color: #5E5E5E;
            text-align: left;
            margin-top: 12px;
        }
    }
    .item_list {
        display: flex;
        margin-top: 40px;
        flex-wrap: wrap;
    }
    .add {
        width: 100%;
        height: 32px;
        margin-top: 20px;
        line-height: 8px;
    }
</style>