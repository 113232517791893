<template>
    <div class="main">
        <div class="news">
            <div class="input_box" style="margin-top: 136px;">
                <span class="input_title">旧密码：</span>
                <el-input v-model="old_pw" size="small" type="password" placeholder="请输入内容"></el-input>
            </div>
            <div class="input_box">
                <span class="input_title">新密码：</span>
                <el-input v-model="new_pw" type="password" size="small" placeholder="请输入内容"></el-input>
            </div>
            <div class="input_box">
                <span class="input_title">确认新密码：</span>
                <el-input v-model="confirm_pw" type="password" size="small" placeholder="请输入内容"></el-input>
            </div>
            <div class="input_box" style="margin-top: 10px">
                <span class="input_title" style="width: 110px"></span>
                <el-button style="width: 86px;" size="small" type="primary" @click="sure">确定</el-button>
                <el-button style="width: 86px;" size="small" @click="goBack">取消</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "modify",
        data() {
            return {
                old_pw:'',
                new_pw:'',
                confirm_pw:''
            }
        },
        methods:{
            goBack() {
                this.$router.go(-1)
            },
            sure() {
                this.$request('/Login/reset_passwrd',{old_pw:this.old_pw,new_pw:this.new_pw,confirm_pw:this.confirm_pw}).then( (res) => {
                    if(res.errcode === 0) {
                        this.$notify({
                            title: '成功',
                            message: '密码修改成功',
                            type: 'success'
                        });
                        this.$router.replace({
                            path:'/'
                        })
                    } else {
                        this.$notify({
                            title: '提醒',
                            message: res.errmsg,
                            type: 'success'
                        });
                    }
                })
            }
        },
        beforeCreate() {
            document
                .querySelector('body')
                .setAttribute('style', 'background-color:#f0f2f5')
        }
    }
</script>

<style scoped lang="scss">
    .main {
        padding-top: 10px;
        .news {
            width: 1180px;
            padding: 0 10px;
            background: #fff;
            height: 716px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            /*justify-content: center;*/
        }
        .input_box {
            width: 540px;
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            .input_title {
                width: 140px;
                text-align: right;
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.847058823529412);
            }
        }
    }
</style>