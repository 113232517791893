<template>
  <div class="zhe" @scroll="handleScroll">
    <div class="form_top">
      <div class="top_left">问卷调查</div>
      <div class="top_right">
        <el-button type="primary" style="width:75px;text-align: center" size="small" @click="exportWord">导出CRF
        </el-button>
        <el-button size="small" style="width:75px;text-align: center;margin-right: 20px;" @click="back">取消</el-button>
      </div>
    </div>
    <div style="width: 100%;height: 51px;"></div>
    <div class="form_bottom">
      <div class="form_left">
        <a href="#I" @click="int = 0" :class="['menu_item',{menu_item_a:int === 0}]">I .人口统计学及入院信息</a>
        <a href="#II" @click="int = 1" :class="['menu_item',{menu_item_a:int === 1}]">II.实验室检查结果</a>
        <a href="#III" @click="int = 2" :class="['menu_item',{menu_item_a:int === 2}]" class="menu_item">III.影像学检查结果</a>
        <a href="#IV" @click="int = 3" :class="['menu_item',{menu_item_a:int === 3}]" class="menu_item">IV.术前膝关节功能评分</a>
        <a href="#V" @click="int = 4" :class="['menu_item',{menu_item_a:int === 4}]" class="menu_item">V.手术时年龄</a>
        <a href="#VI" @click="int = 5" :class="['menu_item',{menu_item_a:int === 5}]" class="menu_item">VI.治疗方式</a>
        <a href="#VII" @click="int = 6" :class="['menu_item',{menu_item_a:int === 6}]" class="menu_item">VII.关节镜所见</a>
        <a href="#VIII" @click="int = 7" :class="['menu_item',{menu_item_a:int === 7}]" class="menu_item">VIII.术后并发症</a>
        <a href="#IX" @click="int = 8" :class="['menu_item',{menu_item_a:int === 8}]" class="menu_item">IX.术后负重时间</a>
        <a href="#X" @click="int = 9" :class="['menu_item',{menu_item_a:int === 9}]" class="menu_item">X.术后是否进行规范化、系统性功能锻炼</a>
        <a href="#X" @click="int = 10" :class="['menu_item',{menu_item_a:int === 10}]" class="menu_item">XI.术后疗效评估</a>
        <a href="#X" @click="int = 11" :class="['menu_item',{menu_item_a:int === 11}]" class="menu_item">XII.随访期间对膝关节不适的处理措施</a>
        <a href="#X" @click="int = 12" :class="['menu_item',{menu_item_a:int === 12}]" class="menu_item">XIII.其他需要特殊说明的情况</a>
      </div>
      <div class="form_right" ref="box">
        <div class="right_center">
          <img class="right_logo" src="../assets/images/u5690.gif" alt="">
          <div class="right_title">人类膝关节外侧盘状半月板遗传方式及致病基因研究信息提取表</div>
          <div class="info">
            <span>填报人</span>
            <span class="info_name">{{ info.member_name }}</span>
            <span class="info_name">填报日期</span>
            <span>{{ info.create_time }}</span>
          </div>
          <div id="I"></div>
          <div class="form_column">
            <div class="line"></div>
            <div class="line-name">I .人口统计学及入院信息</div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">受检者姓名</div>
              <div class="detail_txt">{{ info.name }}</div>
            </div>
            <div class="detail_item">
              <div class="detail_title">身份证号</div>
              <div class="detail_txt">{{ info.card_num }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">登记号</div>
              <div class="detail_txt">{{ info.reg_num }}</div>
            </div>
            <div class="detail_item">
              <div class="detail_title">门诊号</div>
              <div class="detail_txt">{{ info.op_num }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">住院号</div>
              <div class="detail_txt">{{ info.ip_num }}</div>
            </div>
            <div class="detail_item">
              <div class="detail_title">急诊号</div>
              <div class="detail_txt">{{ info.ep_num }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">样本编号</div>
              <div class="detail_txt">{{ info.sample_num }}</div>
            </div>
            <div class="detail_item">
              <div class="detail_title">出生日期</div>
              <div class="detail_txt">{{ info.birthday }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">长期居住地址</div>
              <div class="detail_txt">{{ info.address }}</div>
            </div>
            <div class="detail_item">
              <div class="detail_title">电话号码</div>
              <div class="detail_txt">{{ info.tel }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">入院时年龄</div>
              <div class="detail_txt">{{ info.age }}</div>
            </div>
            <div class="detail_item">
              <div class="detail_title">性别</div>
              <div class="detail_txt">{{ info.gender == 1 ? '男' : '女' }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">体重</div>
              <div class="detail_txt">{{ info.weight }}</div>
            </div>
            <div class="detail_item">
              <div class="detail_title">身高</div>
              <div class="detail_txt">{{ info.height }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">体重指数</div>
              <div class="detail_txt">{{ info.mass }}</div>
            </div>
            <div class="detail_item">
              <div class="detail_title">种族</div>
              <div class="detail_txt">{{ info.race }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">民族</div>
              <div class="detail_txt">{{ info.nation }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">是否具有家族遗传病史</div>
              <div class="detail_txt">{{ info.inherit == 1 ? '否' : '是' }}</div>
            </div>
            <div class="detail_item">
              <div class="detail_title">家族遗传病说明</div>
              <div class="detail_txt">{{ info.inherit_remark }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">是否具有膝关节外侧盘状半月板家族聚集现象</div>
              <div class="detail_txt">{{ info.gather == 1 ? '否' : '是' }}</div>
            </div>
            <div class="detail_item">
              <div class="detail_title">家系遗传图</div>
              <el-image
                  v-for="(img,index) in info.gather_images" :key="index"
                  class="y_img_d"
                  :src="img"
                  :preview-src-list="info.gather_images">
              </el-image>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">日常生活及工作强度</div>
              <div class="detail_txt" v-if="info.levels">{{ info.levels }}级</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">膝关节有无明确受伤病史</div>
              <div class="detail_txt">{{ info.knee_join == 1 ? '无' : '有' }}</div>
            </div>
            <div class="detail_item">
              <div class="detail_title">因何种方式受伤</div>
              <div class="detail_txt">{{ info.knee_join_remark }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">出现临床症状的关节侧别</div>
              <div class="detail_txt">{{ info.joint_side_title }}</div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">首次出现临床症状时年龄</div>
              <div class="detail_txt" v-if="info.first_year_left_knee">左膝：{{ info.first_year_left_knee }}岁
              </div>
              <div class="detail_txt" v-if="info.first_year_right_knee">右膝：{{ info.first_year_right_knee }}岁
              </div>
            </div>
            <div class="detail_item">
              <div class="detail_title">症状持续时间</div>
              <div class="detail_txt" v-if="info.last_time_left_knee">左膝：{{ info.last_time_left_knee }}月</div>
              <div class="detail_txt" v-if="info.last_time_right_knee">右膝：{{ info.last_time_right_knee }}月
              </div>
            </div>
          </div>
          <div class="detail_row">
            <div class="detail_item">
              <div class="detail_title">临床症状的主要表现</div>
              <div class="detail_txt" v-if="info.later_joint">{{ info.later_joint }}</div>
              <div class="detail_txt" v-if="info.other_later_joint">其他症状：{{ info.other_later_joint }}</div>
            </div>
          </div>
          <div id="II"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">II.实验室检查结果</div>
          </div>
          <table id="customers">
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name">血红蛋白：{{ info.laboratory.blood }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">白细胞：{{ info.laboratory.white_cells }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">中性粒细胞：{{ info.laboratory.neutrophil }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">中性粒细胞%：{{ info.laboratory.neutrophil_percent }}</span>
                </div>
              </td>
            </tr>
            <tr class="alt">
              <td>
                <div class="table_box">
                  <span class="table_name">血小板：{{ info.laboratory.platelet }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">白蛋白：{{ info.laboratory.albumin }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">谷草转氨酶：{{ info.laboratory.aspartate }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">谷丙转氨酶：{{ info.laboratory.alanine }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name">直接胆红素：{{ info.laboratory.bilirubin }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">间接胆红素：{{ info.laboratory.indirect }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">空腹血糖：{{ info.laboratory.glucose }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">尿素氮：{{ info.laboratory.nitrogen }}</span>
                </div>
              </td>
            </tr>
            <tr class="alt">
              <td>
                <div class="table_box">
                  <span class="table_name">肌酐：{{ info.laboratory.creatinine }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">血尿酸：{{ info.laboratory.uric_acid }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">K+：{{ info.laboratory.k_jia }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">Na+：{{ info.laboratory.Na_jia }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name">类风湿因子：{{ info.laboratory.rheumatoid }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">抗链球菌溶血素：{{ info.laboratory.anti_streptolysin }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">C反应蛋白：{{ info.laboratory.reactive_protein }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name">血沉：{{ info.laboratory.esr }}</span>
                </div>
              </td>
            </tr>
            <tr class="alt">
              <td>
                <span class="table_name" style="width: 100%;">其他特殊实验室检测结果：</span></td>
              <td colspan="3">{{ info.laboratory.special_result }}</td>
            </tr>
          </table>
          <div id="III"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">III.影像学检查结果</div>
          </div>
          <div class="detail_ying_border" style="height: 196px">
            <div class="detail_ying_title">膝关节X线片</div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">骨关节炎K-L 分级</div>
                <div class="y_txt" v-if="info.esr_data.joint_level">{{ info.esr_data.joint_level }}级</div>
              </div>
              <div class="y_right">
                <img class="y_img" src="../assets/images/u1074.png" alt="">
                <img class="y_img" src="../assets/images/u1075.png" alt="">
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节X线图片</div>
                <el-image
                    class="y_img_d"
                    v-for="(v,index) in info.esr_data.joint_images" :key="index"
                    :src="v"
                    :preview-src-list="info.esr_data.joint_images">
                </el-image>
              </div>
              <div class="y_right">
                <div class="zi"><span class="zimu">A：{{ info.esr_data.level_A }}mm</span><span
                    class="zimu">B：{{ info.esr_data.level_B }}mm</span><span
                    class="zimu">C：{{ info.esr_data.level_C }}mm</span><span
                    class="zimu">D：{{ info.esr_data.level_D }}度数</span></div>
              </div>
            </div>
          </div>
          <div class="detail_ying_border" style="height: 444px">
            <div class="detail_ying_title">膝关节核磁共振</div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">是否是膝关节外侧盘状半月板</div>
                <div class="y_txt">{{ info.esr_data.nuclear == 1 ? '否' : '是' }}</div>
              </div>
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板类型</div>
                <div class="y_txt">{{ info.esr_data.joint_knee }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板是否撕裂</div>
                <div class="y_txt">{{ info.esr_data.is_tear == 1 ? '否' : '是' }}</div>
              </div>
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板撕裂类型</div>
                <div class="y_txt">{{ info.esr_data.slit_type }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板撕裂部位</div>
                <div class="y_txt" v-if="info.esr_data.tear_site">{{ info.esr_data.tear_site }}</div>
                <div class="y_txt" v-if="info.esr_data.other_tear_site">其他：{{ info.esr_data.other_tear_site }}</div>
              </div>
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板及损伤情况磁共振图片</div>
                <el-image
                    class="y_img_d"
                    v-for="(i,index) in info.esr_data.magnetic_images" :key="index"
                    :src="i"
                    :preview-src-list="info.esr_data.magnetic_images">
                </el-image>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节是否具有关节软骨损伤</div>
                <div class="y_txt">{{ info.esr_data.injury == 1 ? '否' : '是' }}</div>
              </div>
              <div class="y_left">
                <div class="y_title">膝关节软骨损伤发生部位</div>
                <div class="y_txt">{{ info.esr_data.cartilage_side }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节磁共振软骨损伤分级</div>
                <div class="y_txt">{{ info.esr_data.injury_level }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节软骨损伤情况磁共振图片</div>
                <el-image
                    class="y_img_d"
                    v-for="(a,index) in info.esr_data.injury_images" :key="index"
                    :src="a"
                    :preview-src-list="info.esr_data.injury_images">
                </el-image>
              </div>
            </div>
          </div>
          <div class="detail_ying_border" style="height: 444px">
            <div class="detail_ying_title">彩色多普勒</div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">是否是膝关节外侧盘状半月板</div>
                <div class="y_txt">{{ info.esr_data.meniscus == 1 ? '否' : '是' }}</div>
              </div>
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板类型</div>
                <div class="y_txt">{{ info.esr_data.lateral_discoid }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板是否撕裂</div>
                <div class="y_txt">{{ info.esr_data.meniscus_tear == 1 ? '否' : '是' }}</div>
              </div>
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板撕裂类型</div>
                <div class="y_txt">{{ info.esr_data.doppler_slit_type }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板撕裂部位</div>
                <div class="y_txt">{{ info.esr_data.tear_meniscus }}</div>
                <div class="y_txt" v-if="info.esr_data.other_tear_meniscus">其他：{{
                    info.esr_data.other_tear_meniscus
                  }}
                </div>
              </div>
              <div class="y_left">
                <div class="y_title">彩超检查外侧盘状半月板图片</div>
                <el-image
                    class="y_img_d"
                    v-for="(b,index) in info.esr_data.doppler_ultrasound" :key="index"
                    :src="b"
                    :preview-src-list="info.esr_data.doppler_ultrasound">
                </el-image>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">彩超检查外侧盘状半月板损伤情况图片</div>
                <el-image
                    class="y_img_d"
                    v-for="(c,index) in info.esr_data.ultrasound_examination" :key="index"
                    :src="c"
                    :preview-src-list="info.esr_data.ultrasound_examination">
                </el-image>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">术前主要诊断</div>
                <div class="y_txt">{{ info.esr_data.diagnosis }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left" style="width: 100%">
                <div class="y_title">术前合并膝关节病损</div>
                <div class="y_txt">{{ info.esr_data.preoperative }}</div>
                <div class="y_txt" v-if="info.esr_data.other_preoperative">其他膝关节疾病：{{
                    info.esr_data.other_preoperative
                  }}
                </div>
                <!--                <el-checkbox-group style="width: 440px;margin-left: 10px;" disabled-->
                <!--                                   v-model="info.esr_data.preoperative">-->
                <!--                  <el-checkbox style="margin-bottom:8px;" label="膝关节周围或前后交叉韧带损伤">膝关节周围或前后交叉韧带损伤</el-checkbox>-->
                <!--                  <el-checkbox style="margin-bottom:8px;" label="关节周围骨折">关节周围骨折</el-checkbox>-->
                <!--                  <el-checkbox style="margin-bottom:8px;" label="骨关节炎">骨关节炎</el-checkbox>-->
                <!--                  <el-checkbox style="margin-bottom:8px;" label="化脓性关节炎">化脓性关节炎</el-checkbox>-->
                <!--                  <el-checkbox style="margin-bottom:8px;" label="其他膝关节疾病">其他膝关节疾病</el-checkbox>-->
                <!--                </el-checkbox-group>-->
              </div>
            </div>
          </div>
          <div id="IV"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">IV.术前膝关节功能评分</div>
          </div>
          <table id="vas">
            <tr>
              <td>
                <div class="table_box">
                  <span class="vas_name">左</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="vas_name">右</span>
                </div>
              </td>
            </tr>
            <tr class="alt">
              <td>
                <div class="table_box">
                  <span class="table_name_vas">VAS 评分 (总分10，只取整数)：{{ info.score.vas_left }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">VAS 评分 (总分10，只取整数)：{{ info.score.vas_right }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">Lysholm 评分(总分100)：{{ info.score.lysholm_left }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">Lysholm 评分(总分100)：{{ info.score.lysholm_right }}</span>
                </div>
              </td>
            </tr>
            <tr class="alt">
              <td>
                <div class="table_box">
                  <span class="table_name_vas">Tegner 评分(总分10)：{{ info.score.tegner_left }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">Tegner 评分(总分10)：{{ info.score.tegner_right }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">IKDC 评分(总分83)：{{ info.score.ikdc_left }}</span>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name_vas">IKDC 评分(总分83)：{{ info.score.ikdc_right }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="table_box">
                  <span class="table_name_vas" style="width: 120px;">Ikeuchi 分级：</span>
                  <el-radio-group style="width:250px" v-model="info.score.lkeuchi_left">
                    <el-radio :label="1">优</el-radio>
                    <el-radio :label="2">良</el-radio>
                    <el-radio :label="3">中</el-radio>
                    <el-radio :label="4">差</el-radio>
                  </el-radio-group>
                </div>
              </td>
              <td>
                <div class="table_box">
                  <span class="table_name_vas" style="width: 120px;">Ikeuchi 分级：</span>
                  <el-radio-group style="width:250px" v-model="info.score.lkeuchi_right">
                    <el-radio :label="1">优</el-radio>
                    <el-radio :label="2">良</el-radio>
                    <el-radio :label="3">中</el-radio>
                    <el-radio :label="4">差</el-radio>
                  </el-radio-group>
                </div>
              </td>
            </tr>
          </table>
          <div id="V"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">V.手术时年龄</div>
          </div>
          <div class="y_txt" style="margin: 20px 0 0 10px">{{ info.operation.operation_age }}岁</div>
          <div id="VI"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">VI.治疗方式</div>
          </div>
          <div class="y_txt" style="margin: 20px 0 0 10px">{{ info.operation.treatment }}</div>
          <!--          <el-checkbox-group style="width: 440px;margin-top: 20px" v-model="info.operation.treatment">-->
          <!--            <div>-->
          <!--              <el-checkbox style="margin-bottom:8px;" label="非手术治疗">非手术治疗</el-checkbox>-->
          <!--            </div>-->
          <!--            <div>-->
          <!--              <el-checkbox style="margin-bottom:8px;" label="外侧盘状半月板修整成形术">外侧盘状半月板修整成形术</el-checkbox>-->
          <!--            </div>-->
          <!--            <div>-->
          <!--              <el-checkbox style="margin-bottom:8px;" label="外侧盘状半月板修整成形+缝合修复术">外侧盘状半月板修整成形+缝合修复术</el-checkbox>-->
          <!--            </div>-->
          <!--            <div>-->
          <!--              <el-checkbox style="margin-bottom:8px;" label="外侧盘状半月板部分切除术">外侧盘状半月板部分切除术</el-checkbox>-->
          <!--            </div>-->
          <!--            <div>-->
          <!--              <el-checkbox style="margin-bottom:8px;" label="外侧盘状半月板完全切除术">外侧盘状半月板完全切除术</el-checkbox>-->
          <!--            </div>-->
          <!--          </el-checkbox-group>-->
          <div class="question">
            <div class="question_name">关节镜下手术治疗后整体观</div>
            <div class="img_box">
              <el-image
                  class="guan_img"
                  v-for="(d,index) in info.operation.treatment_images" :key="index"
                  :src="d"
                  :preview-src-list="info.operation.treatment_images">
              </el-image>
            </div>
          </div>
          <div id="VII"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">VII.关节镜所见</div>
          </div>
          <div style="padding: 0 20px">
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">是否是膝关节外侧盘状半月板</div>
                <div class="y_txt">{{ info.operation.is_meniscus == 1 ? '否' : '是' }}</div>
              </div>
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板类型</div>
                <div class="y_txt">{{ info.operation.meniscus_type }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">镜下盘状半月板整体观图片</div>
                <el-image
                    class="y_img_d"
                    v-for="(f,index) in info.operation.panoramic_view_images" :key="index"
                    :src="f"
                    :preview-src-list="info.operation.panoramic_view_images">
                </el-image>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板是否撕裂</div>
                <div class="y_txt">{{ info.operation.is_meniscus_tear == 1 ? '否' : '是' }}</div>
              </div>
              <div class="y_left">
                <div class="y_title">膝关节外侧盘状半月板撕裂部位</div>
                <div class="y_txt">{{ info.operation.meniscus_joint }}</div>
                <div class="y_txt" v-if="info.operation.other_meniscus_joint">其他：{{
                    info.operation.other_meniscus_joint
                  }}
                </div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">外半月板损伤O'Connor 分型</div>
                <div class="y_txt">{{ info.operation.connor }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">盘状半月板损伤情况图片</div>
                <el-image
                    class="y_img_d"
                    v-for="(g,index) in info.operation.meniscus_injury_imgs" :key="index"
                    :src="g"
                    :preview-src-list="info.operation.meniscus_injury_imgs">
                </el-image>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节是否具有关节软骨损伤</div>
                <div class="y_txt">{{ info.operation.is_cartilage_damage == 1 ? '否' : '是' }}</div>
              </div>
              <div class="y_left">
                <div class="y_title">膝关节软骨损伤发生部位</div>
                <div class="y_txt">{{ info.operation.clinical_symptoms }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">关节镜下Outerbridge软骨损伤分级</div>
                <div class="y_txt">{{ info.operation.outerbridge_level }}</div>
              </div>
              <div class="y_left">
                <div class="y_title">关节镜下ICRS软骨损伤分级</div>
                <div class="y_txt">{{ info.operation.lcrs_level }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节软骨损伤情况图片</div>
                <el-image
                    class="y_img_d"
                    v-for="(h,index) in info.operation.cartilage_injury_imgs" :key="index"
                    :src="h"
                    :preview-src-list="info.operation.cartilage_injury_imgs">
                </el-image>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">内侧半月板是否为盘状半月板</div>
                <div class="y_txt">{{ info.operation.is_meniscus_discoid == 1 ? '否' : '是' }}</div>
              </div>
              <div class="y_left">
                <div class="y_title">内侧半月板是否撕裂</div>
                <div class="y_txt">{{ info.operation.is_meniscus_torn == 1 ? '否' : '是' }}</div>
              </div>
            </div>
            <div class="detail_ying_row">
              <div class="y_left">
                <div class="y_title">膝关节内侧盘状半月板撕裂部位</div>
                <div class="y_txt">{{ info.operation.medial_discoid_meniscus }}</div>
                <div class="y_txt" v-if="info.operation.other_medial_discoid_meniscus">
                  其他：{{ info.operation.other_medial_discoid_meniscus }}
                </div>
              </div>
              <div class="y_left">
                <div class="y_title">内侧半月板损伤O'Connor 分型</div>
                <div class="y_txt">{{ info.operation.inside_connor }}</div>
              </div>
            </div>
          </div>
          <div id="VIII"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">VIII.术后并发症</div>
          </div>
          <div class="y_txt" style="margin: 20px 0 0 10px">{{ info.operation.operation_complications }}</div>
          <div class="y_txt" style="margin: 20px 0 0 10px" v-if="info.operation.other_complications">
            其他情况：{{ info.operation.other_complications }}
          </div>
          <!--          <el-checkbox-group style="margin-top: 30px" v-model="info.operation.operation_complications">-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="无并发症">无并发症</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="膝关节疼痛">膝关节疼痛</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="膝关节活动障碍">膝关节活动障碍</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="切口感染">切口感染</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="切口愈合不良">切口愈合不良</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="关节腔内感染">关节腔内感染</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="术后关节腔出血">术后关节腔出血</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="下肢深静脉血栓">下肢深静脉血栓</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="肺栓塞">肺栓塞</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="压疮">压疮</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="肺炎">肺炎</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="泌尿系感染">泌尿系感染</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 25%" label="半月板再破裂">半月板再破裂</el-checkbox>-->
          <!--            <el-checkbox style="margin-bottom:8px;width: 35%" label="其他情况">其他情况</el-checkbox>-->
          <!--          </el-checkbox-group>-->
          <div id="IX"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">IX.术后负重时间</div>
          </div>
          <div class="y_txt" style="margin: 20px 0 0 10px">{{ info.operation.bearing_time }}月</div>
          <div id="X"></div>
          <div class="form_column" style="margin-top:50px;">
            <div class="line"></div>
            <div class="line-name">X.术后是否进行规范化、系统性功能锻炼</div>
          </div>
          <div class="y_txt" style="margin: 20px 0 20px 10px">{{ info.operation.is_exercise }}</div>
          <div class="follow" v-for="(item,index) in info.follow" :key="index">
            <div class="follow_lan">
              <div class="follow_name" style="margin-bottom: 10px">术后随访时间 <span
                  class="year">{{ item.follow_time }}</span></div>
            </div>
            <div class="form_column">
              <div class="line"></div>
              <div class="line-name">XI.术后疗效评估</div>
            </div>
            <table id="vas">
              <tr>
                <td>
                  <div class="table_box">
                    <span class="vas_name">左</span>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="vas_name">右</span>
                  </div>
                </td>
              </tr>
              <tr class="alt">
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">VAS 评分 (总分10，只取整数)：{{ item.follow_vas_left }}</span>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">VAS 评分 (总分10，只取整数)：{{ item.follow_vas_right }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">Lysholm 评分(总分100)：{{ item.follow_lysholm_left }}</span>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">Lysholm 评分(总分100)：{{ item.follow_lysholm_right }}</span>
                  </div>
                </td>
              </tr>
              <tr class="alt">
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">Tegner 评分(总分10)：{{ item.follow_tegner_left }}</span>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">Tegner 评分(总分10)：{{ item.follow_tegner_right }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">IKDC 评分(总分83)：{{ item.follow_lkdc_left }}</span>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas">IKDC 评分(总分83)：{{ item.follow_lkdc_right }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas" style="width: 105px;">Ikeuchi 分级：</span>
                    <el-radio-group style="width:250px" v-model="item.follow_lkeuchi_left">
                      <el-radio :label="1">优</el-radio>
                      <el-radio :label="2">良</el-radio>
                      <el-radio :label="3">中</el-radio>
                      <el-radio :label="4">差</el-radio>
                    </el-radio-group>
                  </div>
                </td>
                <td>
                  <div class="table_box">
                    <span class="table_name_vas" style="width: 105px;">Ikeuchi 分级：</span>
                    <el-radio-group style="width:250px" v-model="item.follow_lkeuchi_right">
                      <el-radio :label="1">优</el-radio>
                      <el-radio :label="2">良</el-radio>
                      <el-radio :label="3">中</el-radio>
                      <el-radio :label="4">差</el-radio>
                    </el-radio-group>
                  </div>
                </td>
              </tr>
            </table>
            <div class="form_column" style="margin-top:30px">
              <div class="line"></div>
              <div class="line-name">XII.随访期间对膝关节不适的处理措施</div>
            </div>
            <div class="y_txt" style="margin: 20px 0 20px 10px">{{ item.treatment_measures }}</div>
            <div class="y_txt" style="margin: 20px 0 20px 10px" v-if="item.other_treatment_measures">
              其他：{{ item.other_treatment_measures }}
            </div>
            <!--            <el-checkbox-group style="margin-top: 30px" v-model="item.treatment_measures">-->
            <!--              <el-checkbox style="margin-bottom:8px;width: 30%" label="无不适">无不适</el-checkbox>-->
            <!--              <el-checkbox style="margin-bottom:8px;width: 30%" label="心理疏导、指导康复锻炼，未行药物干预">心理疏导、指导康复锻炼，未行药物干预-->
            <!--              </el-checkbox>-->
            <!--              <el-checkbox style="margin-bottom:8px;width: 30%" label="对症性药物干预">对症性药物干预</el-checkbox>-->
            <!--              <el-checkbox style="margin-bottom:8px;width: 20%" label="再次手术">再次手术</el-checkbox>-->
            <!--              <el-checkbox style="margin-bottom:8px;width: 35%" label="其他">其他</el-checkbox>-->
            <!--            </el-checkbox-group>-->
            <div class="form_column" style="margin-top:30px">
              <div class="line"></div>
              <div class="line-name">XIII.其他需要特殊说明的情况</div>
            </div>
            <div class="area">{{ item.remark_special }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {baseURL} from "../config";

export default {
  name: "detail",
  data() {
    return {
      info: '',
      int: 0,
      head: ''
    }
  },
  props: {
    id: {
      type: String
    }
  },
  methods: {
    getInfo() {
      this.$request('/Question/question_detail', {question_id: this.id}).then((res) => {
        this.info = res.data
      })
    },
    getHead() {
      this.$request('/Member/member_info').then((res) => {
        this.head = res.data
      })
    },
    handleScroll(e) {
      let height = e.target.scrollTop
      if (height < 1106) {
        this.int = 0
      } else if (1106 <= height && height < 1545) {
        this.int = 1
      } else if (1545 <= height && height < 2836) {
        this.int = 2
      } else if (2836 <= height && height < 3272) {
        this.int = 3
      } else if (3272 <= height && height < 3397) {
        this.int = 4
      } else if (3397 <= height && height < 3687) {
        this.int = 5
      } else if (3687 <= height && height < 4382) {
        this.int = 6
      } else if (4382 <= height && height < 4630) {
        this.int = 7
      } else if (4630 <= height && height < 4820) {
        this.int = 8
      } else if (height > 4820) {
        this.int = 9
      }
    },
    exportWord() {
      // window.location.href=`https://wcb-wenjuan.mini.bandaochina.com/enjoy/enjoy/index?id=${this.id}`
      // window.open(`https://wcb-wenjuan.mini.bandaochina.com/enjoy/enjoy/index?id=${this.id}`)

      window.open(`${baseURL}/enjoy/enjoy/index?id=${this.id}`)
    },
    back() {
      this.$parent.lookDetail();
    }
  },
  created() {
    this.getInfo();
    this.getHead();
  }
}
</script>

<style scoped>
* {
  text-align: left;
}

.zhe {
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow-y: auto;
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

#customers td, #customers th {
  font-size: 1em;
  border: 1px solid #e4e4e4;
  padding: 3px 7px 2px 7px;
}

#customers th {
  font-size: 1.1em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #A7C942;
  color: #ffffff;
}

#customers tr td {
  color: #000000;
  background-color: #f8f8fa;
  width: 194px;
  height: 50px;
}

#vas {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

#vas td, #vas th {
  font-size: 1em;
  border: 1px solid #e4e4e4;
  padding: 3px 7px 2px 7px;
}

#vas th {
  font-size: 1.1em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #A7C942;
  color: #ffffff;
}

#vas tr td {
  color: #000000;
  background-color: #f8f8fa;
  height: 50px;
}

.form_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #e4e4e4;
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.top_left {
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  color: #343434;
  margin-left: 20px;
}

.menu_item {
  padding: 10px;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  color: #1C313A;
  cursor: pointer;
  display: block;
  text-decoration: none;
}

.menu_item:hover {
  background: #f8f8f8;
}

.menu_item_a {
  background: #f8f8f8;
}

.form_left {
  width: 180px;
  border-right: 5px solid #f0f2f5;
  padding: 20px 10px;
  position: fixed;
  left: 0;
  top: 50px;
  height: 100%;
}

.form_right {
  width: 100%;
}

.form_bottom {
  display: flex;
}

.right_center {
  width: 776px;
  margin: 0 auto;
}

.right_logo {
  width: 409px;
  height: 150px;
  display: block;
  margin: 0 auto;
}

.right_title {
  font-family: '隶书';
  margin: 10px auto;
  width: 648px;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  color: #0070C0;
}

.info {
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  color: #868686;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.form_column {
  height: 32px;
  display: flex;
  align-items: center;
  background: #f8f8fa;
}

.info_name {
  margin: 0 20px 0 20px;
}

.row_name {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1C313A;
  margin-bottom: 10px;
  margin-left: 2px;
}

.form_row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.line {
  height: 20px;
  border-left: 3px solid #267330;
  margin-right: 10px;
}

.line-name {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #3E3A39;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .avatar {
  width: 80px;
  height: 80px;
}

.table_name {
  display: block;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1C313A;
  text-align: right;
}

.table_name_vas {
  display: block;
  width: 200px;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1C313A;
  text-align: right;
}

.table_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.question {
  margin-top: 20px;
}

.question_name {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1C313A;
  margin-bottom: 12px;
}

.vas_name {
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: #267330;
}

.follow {
  border: 1px dotted #d5d5d5;
  padding: 10px;
  margin-bottom: 20px;
}

.follow_name {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #1C313A;
}

.follow_lan {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shan {
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  color: #FF6868;
  margin-right: 20px;
  cursor: pointer;
}

.follow_btn {
  width: 776px;
  height: 35px;
  border: 1px dotted #d5d5d5;
  text-align: center;
  line-height: 35px;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  color: #267330;
  margin-top: 20px;
  border-radius: 4px;
  margin-bottom: 60px;
  cursor: pointer;
}

.detail_row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 20px;
}

.detail_item {
  display: flex;
  width: 50%;
}

.detail_title {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #797979;
  width: 100px;
}

.detail_txt {
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  color: #222222;
  margin-left: 10px;
}

.detail_ying_border {
  padding: 10px 20px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin-top: 20px;
}

.detail_ying_title {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #226527;
}

.detail_ying_row {
  display: flex;
  margin-top: 20px;
}

.y_left {
  width: 50%;
  display: flex;
}

.y_right {
  width: 50%;
  display: flex;
}

.zi {
  display: flex;
  justify-content: space-between;
}

.zimu {
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #333333;
  margin-right: 30px;
}

.y_img_d {
  width: 50px;
  height: 50px;
  margin-left: 8px;
}

.y_title {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #797979;
  line-height: 20px;
  width: 100px;
}

.y_txt {
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  color: #222222;
  margin-left: 10px;
}

.y_img {
  width: 69px;
  height: 85px;
}

.img_box {
  display: flex;
}

.guan_img {
  width: 84px;
  height: 84px;
  margin-right: 8px;
}

.year {
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  color: #222222;
  margin-left: 20px;
}

.area {
  padding: 20px;
  font-size: 13px;
  font-weight: 400;
  color: #222;
  min-height: 50px;
}
</style>
