<template>
  <div class="main">
    <div class="news">
      <div class="news_left">
        <div class="news_title">
          <div class="title_line"></div>
          <div class="news_name">受检者信息查询</div>
        </div>
        <div class="news_form">
          <div class="rows">
            <div class="row_left">
              <el-select v-model="type" style="width: 100px;margin-right: 10px;" size="small" placeholder="请选择">
                <el-option
                    v-for="item in typeArr"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>

              <!--                            <el-input size="small" style="width:180px;" clearable v-model="search_data" placeholder="请输入"></el-input>-->
            </div>
            <div class="row_right">
              <div class="right_name">性别</div>
              <el-select v-model="gender" clearable style="width: 90px" size="small" placeholder="请选择">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="rows">
            <div class="row_left">
              <div class="left_name">姓名</div>
              <a-select mode="tags" v-model="name" placeholder="请输入" class="test" style="width: 180px"
                        :token-separators="[' ']"></a-select>
              <!--                            <el-input size="small" style="width:180px;" clearable v-model="name" placeholder="请输入"></el-input>-->
            </div>
            <div class="row_right">
              <div class="right_name">种族</div>
              <a-select mode="tags" v-model="race" placeholder="请输入" class="test" style="width: 180px"
                        :token-separators="[' ']"></a-select>
              <!--                            <el-select v-model="race" clearable multiple style="width: 180px" size="small" placeholder="请选择">-->
              <!--                                <el-option-->
              <!--                                        v-for="item in color"-->
              <!--                                        :key="item.value"-->
              <!--                                        :label="item.label"-->
              <!--                                        :value="item.value">-->
              <!--                                </el-option>-->
              <!--                            </el-select>-->
            </div>
          </div>
          <div class="rows">
            <div class="row_left">
              <div class="left_name">年龄</div>
              <div class="year">
                <input style="margin-left:10px;" type="number" class="num" v-model="member_age_start" placeholder="最小值">
                ~
                <input style="margin-right:10px;" type="number" class="num" v-model="member_age_end" placeholder="最大值">
              </div>
            </div>
            <div class="row_right">
              <div class="right_name">民族</div>
              <a-select mode="tags" v-model="nation" placeholder="请输入" class="test" style="width: 180px"
                        :token-separators="[' ']"></a-select>
              <!--                            <el-select v-model="nation" clearable multiple style="width: 180px" size="small" placeholder="请选择">-->
              <!--                                <el-option-->
              <!--                                        v-for="item in nation_list"-->
              <!--                                        :key="item"-->
              <!--                                        :label="item"-->
              <!--                                        :value="item">-->
              <!--                                </el-option>-->
              <!--                            </el-select>-->
            </div>
          </div>
          <div class="rows">
            <div class="row_left">
              <div class="left_name">入院时年龄</div>
              <div class="year">
                <input style="margin-left:10px;" class="num" type="number" v-model="age_start" placeholder="最小值">
                ~
                <input style="margin-right:10px;" class="num" type="number" v-model="age_end" placeholder="最大值">
              </div>
            </div>
            <!--                        <div class="row_right">-->
            <!--                            <div class="right_name">备注</div>-->
            <!--                            <el-input style="width: 180px" size="small" v-model="input" placeholder="请输入"></el-input>-->
            <!--                        </div>-->
          </div>
          <div class="btn_box">
            <el-button size="small" style="width:86px;margin-left:30px;" type="primary" @click="getList">查询</el-button>
            <el-button size="small" style="width:86px;" @click="resetData">重置</el-button>
          </div>
        </div>
      </div>
      <div class="news_right">
        <div class="news_title">
          <div class="title_line"></div>
          <div class="news_name">填报信息查询</div>
        </div>
        <div class="row_left" style="margin-top: 14px;">
          <div style="width: 80px" class="left_name">填报人</div>
          <a-select mode="tags" v-model="person_id" placeholder="请输入" class="test" style="width: 180px"
                    :token-separators="[' ']"></a-select>
          <!--                    <el-select v-model="person_id" clearable multiple style="width: 180px" size="small" placeholder="请选择">-->
          <!--                        <el-option-->
          <!--                                v-for="item in person_list"-->
          <!--                                :key="item.id"-->
          <!--                                :label="item.name"-->
          <!--                                :value="item.id">-->
          <!--                        </el-option>-->
          <!--                    </el-select>-->
        </div>
        <div class="row_left" style="margin-top:14px;">
          <div style="width: 80px" class="left_name">填报日期</div>
          <el-date-picker
              v-model="dateValue"
              size="small"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="changeDate"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div v-if="!empty">
      <img class="empty" src="../assets/images/empty.svg" alt="">
      <div class="empty_txt">数据为空</div>
      <div class="empty_tips">建议您先选择相关查询条件进行查询</div>
    </div>
    <div class="table_box" v-else>
      <div class="table_top">
        <div class="table_left">
          <el-input
              placeholder="请输入"
              clearable
              suffix-icon="el-icon-search"
              size="small"
              style="width: 280px"
              v-model="name_txt">
          </el-input>
          <el-button size="small" style="margin-left: 20px" @click="getList">查询</el-button>
          <img class="tan" src="../assets/images/u715.png" alt="">
          <span class="tan_txt">共{{ count ? count : 0 }}条，选中{{ selectArr.length }}条</span>
        </div>
        <div class="table_right">
          <el-tooltip class="item" effect="dark" content="双击下方表格也可以进行数据的全部展示" placement="top-start">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
          <el-button size="small" style="margin-left: 10px" @click="showTable">展示全部</el-button>
          <el-button size="small" type="primary" @click="exportList">导出excel</el-button>
        </div>
      </div>
      <div @dblclick="showTable">
        <el-table
            ref="multipleTable"
            :data="tableData"
            border
            size="small"
            header-cell-style="background:#f8f8fa"
            style="width: 100%;margin-top: 20px"
            @selection-change="handleSelectionChange"
        >
          <el-table-column label="人口统计学及入院信息">
            <el-table-column
                type="selection"
                fixed
                width="55">
            </el-table-column>
            <el-table-column
                prop="name"
                label="受检者项目"
                fixed
                width="150"
            >
            </el-table-column>
            <el-table-column
                prop="card_num"
                width="160"
                label="身份证号">
            </el-table-column>
            <el-table-column
                prop="reg_num"
                width="110"
                label="登记号">
            </el-table-column>
            <el-table-column
                prop="op_num"
                width="110"
                label="门诊号">
            </el-table-column>
            <el-table-column
                prop="ip_num"
                width="110"
                label="住院号">
            </el-table-column>
            <el-table-column
                prop="ep_num"
                width="110"
                label="急诊号">
            </el-table-column>
            <el-table-column
                prop="sample_num"
                width="130"
                label="样本编号">
            </el-table-column>
            <el-table-column
                prop="birthday"
                width="110"
                label="出生日期">
            </el-table-column>
            <el-table-column
                prop="member_age"
                width="110"
                label="当前年龄">
            </el-table-column>
            <el-table-column
                prop="address"
                width="130"
                label="长期居住地址">
            </el-table-column>
            <el-table-column
                prop="tel"
                width="110"
                label="电话号码">
            </el-table-column>
            <el-table-column
                prop="age"
                width="110"
                label="入院时年龄">
            </el-table-column>
            <el-table-column
                prop="gender"
                width="110"
                label="性别">
            </el-table-column>
            <el-table-column
                prop="weight"
                width="110"
                label="体重">
            </el-table-column>
            <el-table-column
                prop="height"
                width="110"
                label="身高">
            </el-table-column>
            <el-table-column
                prop="mass"
                width="110"
                label="体重指数(kg/m)">
            </el-table-column>
            <el-table-column
                prop="race"
                width="110"
                label="种族">
            </el-table-column>
            <el-table-column
                prop="nation"
                width="110"
                label="民族">
            </el-table-column>
            <el-table-column
                prop="inherit"
                width="150"
                label="是否具有家族遗传病史">
            </el-table-column>
            <el-table-column
                prop="inherit_remark"
                width="110"
                label="家族遗传病说明">
            </el-table-column>
            <el-table-column
                prop="gather"
                width="300"
                label="是否具有膝关节外侧盘状半月板家族聚集现象">
            </el-table-column>
            <el-table-column
                width="110"
                align="center"
                label="家系遗传图">
              <template slot-scope="scope">
                <el-image
                    v-if="scope.row.images && scope.row.images.length"
                    style="width: 40px; height: 40px"
                    fit="contain"
                    :src="scope.row.images[0]"
                    :preview-src-list="scope.row.images">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
                prop="levels"
                width="150"
                label="日常生活及工作强度">
            </el-table-column>
            <el-table-column
                prop="knee_join"
                width="170"
                label="膝关节有无明确受伤病史">
            </el-table-column>
            <el-table-column
                prop="knee_join_remark"
                width="110"
                label="因何种方式受伤">
            </el-table-column>
            <el-table-column
                prop="joint_side_title"
                width="160"
                label="出现临床症状的关节侧别">
            </el-table-column>
            <el-table-column
                prop="first_year_left_knee"
                width="200"
                label="首次出现临床症状时年龄-左膝">
            </el-table-column>
            <el-table-column
                prop="first_year_right_knee"
                width="200"
                label="首次出现临床症状时年龄-右膝">
            </el-table-column>
            <el-table-column
                prop="last_time_left_knee"
                width="150"
                label="症状持续时间(月)-左膝">
            </el-table-column>
            <el-table-column
                prop="last_time_right_knee"
                width="150"
                label="症状持续时间(月)-右膝">
            </el-table-column>
            <el-table-column
                prop="later_joint"
                width="200"
                label="临床症状的主要表现">
            </el-table-column>
            <el-table-column
                prop="other_later_joint"
                width="110"
                label="其他症状">
            </el-table-column>
          </el-table-column>
          <el-table-column label="实验室检查结果">
            <el-table-column
                prop="cruorin"
                width="110"
                label="血红蛋白">
            </el-table-column>
            <el-table-column
                prop="white_cells"
                width="110"
                label="白细胞">
            </el-table-column>
            <el-table-column
                prop="neutrophil"
                width="110"
                label="中性粒细胞">
            </el-table-column>
            <el-table-column
                prop="neutrophil_percent"
                width="110"
                label="中性粒细胞(%)">
            </el-table-column>
            <el-table-column
                prop="platelet"
                width="110"
                label="血小板">
            </el-table-column>
            <el-table-column
                prop="albumin"
                width="110"
                label="血蛋白">
            </el-table-column>
            <el-table-column
                prop="aspartate"
                width="110"
                label="谷草转氨酶">
            </el-table-column>
            <el-table-column
                prop="alanine"
                width="110"
                label="谷丙转氨酶">
            </el-table-column>
            <el-table-column
                prop="bilirubin"
                width="110"
                label="直接胆红素">
            </el-table-column>
            <el-table-column
                prop="indirect"
                width="110"
                label="间接胆红素">
            </el-table-column>
            <el-table-column
                prop="glucose"
                width="110"
                label="空腹血糖">
            </el-table-column>
            <el-table-column
                prop="nitrogen"
                width="110"
                label="尿素氮">
            </el-table-column>
            <el-table-column
                prop="creatinine"
                width="110"
                label="肌酐">
            </el-table-column>
            <el-table-column
                prop="uric_acid"
                width="110"
                label="血尿酸">
            </el-table-column>
            <el-table-column
                prop="k_jia"
                width="110"
                label="K+">
            </el-table-column>
            <el-table-column
                prop="Na_jia"
                width="110"
                label="Na+">
            </el-table-column>
            <el-table-column
                prop="rheumatoid"
                width="110"
                label="类风湿因子">
            </el-table-column>
            <el-table-column
                prop="anti_streptolysin"
                width="180"
                label="类风湿因子抗链球菌溶血素">
            </el-table-column>
            <el-table-column
                prop="reactive_protein"
                width="110"
                label="C反应蛋白">
            </el-table-column>
            <el-table-column
                prop="esr"
                width="110"
                label="血沉（ESR）">
            </el-table-column>
            <el-table-column
                prop="special_result"
                width="190"
                label="其他特殊实验室检测结果">
            </el-table-column>
          </el-table-column>
          <el-table-column label="影像学检查结果">
            <el-table-column label="膝关节X线片">
              <el-table-column
                  prop="joint_level"
                  width="120"
                  label="骨关节炎K-L 分级">
              </el-table-column>
              <el-table-column
                  prop="level_A"
                  width="110"
                  label="膝关节X线片A">
              </el-table-column>
              <el-table-column
                  prop="level_B"
                  width="110"
                  label="膝关节X线片B">
              </el-table-column>
              <el-table-column
                  prop="level_C"
                  width="110"
                  label="膝关节X线片C">
              </el-table-column>
              <el-table-column
                  prop="level_D"
                  width="110"
                  label="膝关节X线片D">
              </el-table-column>
            </el-table-column>
            <el-table-column label="膝关节核磁共振">
              <el-table-column
                  prop="nuclear"
                  width="180"
                  label="是否是膝关节外侧盘状半月板">
              </el-table-column>
              <el-table-column
                  prop="joint_knee"
                  width="180"
                  label="膝关节外侧盘状半月板类型">
              </el-table-column>
              <el-table-column
                  prop="is_tear"
                  width="200"
                  label="膝关节外侧盘状半月板是否撕裂">
              </el-table-column>
              <el-table-column
                  prop="slit_type"
                  width="200"
                  label="膝关节外侧盘状半月板撕裂类型">
              </el-table-column>
              <el-table-column
                  prop="tear_site"
                  width="200"
                  label="膝关节外侧盘状半月板撕裂部位">
              </el-table-column>
              <el-table-column
                  prop="other_tear_site"
                  width="110"
                  label="其他">
              </el-table-column>
              <el-table-column
                  prop="injury"
                  width="200"
                  label="膝关节是否具有关节软骨损伤">
              </el-table-column>
              <el-table-column
                  prop="cartilage_side"
                  width="200"
                  label="膝关节软骨损伤发生部位">
              </el-table-column>
              <el-table-column
                  prop="injury_level"
                  width="200"
                  label="膝关节磁共振软骨损伤分级">
              </el-table-column>
            </el-table-column>
            <el-table-column label="彩色多普勒">
              <el-table-column
                  prop="meniscus"
                  width="200"
                  label="是否是膝关节外侧盘状半月板">
              </el-table-column>
              <el-table-column
                  prop="lateral_discoid"
                  width="200"
                  label="膝关节外侧盘状半月板类型">
              </el-table-column>
              <el-table-column
                  prop="meniscus_tear"
                  width="200"
                  label="膝关节外侧盘状半月板是否撕裂">
              </el-table-column>
              <el-table-column
                  prop="doppler_slit_type"
                  width="200"
                  label="膝关节外侧盘状半月板撕裂类型">
              </el-table-column>
              <el-table-column
                  prop="tear_meniscus"
                  width="200"
                  label="膝关节外侧盘状半月板撕裂部位">
              </el-table-column>
              <el-table-column
                  prop="other_tear_meniscus"
                  width="110"
                  label="其他">
              </el-table-column>
              <el-table-column
                  prop="diagnosis"
                  width="200"
                  label="术前主要诊断">
              </el-table-column>
              <el-table-column
                  prop="preoperative"
                  width="200"
                  label="术前合并膝关节病损">
              </el-table-column>
              <el-table-column
                  prop="other_preoperative"
                  width="200"
                  label="其他膝关节疾病">
              </el-table-column>
            </el-table-column>
          </el-table-column>
          <el-table-column label="术前膝关节功能评分">
            <el-table-column label="左">
              <el-table-column
                  prop="vas_left"
                  width="110"
                  label="VAS 评分">
              </el-table-column>
              <el-table-column
                  prop="lysholm_left"
                  width="110"
                  label="Lysholm 评分">
              </el-table-column>
              <el-table-column
                  prop="tegner_left"
                  width="110"
                  label="Tegner 评分">
              </el-table-column>
              <el-table-column
                  prop="ikdc_left"
                  width="110"
                  label="IKDC 评分">
              </el-table-column>
              <el-table-column
                  prop="lkeuchi_left"
                  width="110"
                  label="Ikeuchi 分级">
              </el-table-column>
            </el-table-column>
            <el-table-column label="右">
              <el-table-column
                  prop="vas_right"
                  width="110"
                  label="VAS 评分">
              </el-table-column>
              <el-table-column
                  prop="lysholm_right"
                  width="110"
                  label="Lysholm 评分">
              </el-table-column>
              <el-table-column
                  prop="tegner_right"
                  width="110"
                  label="Tegner 评分">
              </el-table-column>
              <el-table-column
                  prop="ikdc_right"
                  width="110"
                  label="IKDC 评分">
              </el-table-column>
              <el-table-column
                  prop="lkeuchi_right"
                  width="110"
                  label="Ikeuchi 分级">
              </el-table-column>
            </el-table-column>
          </el-table-column>
          <el-table-column
              prop="operation_age"
              width="110"
              label="手术时年龄">
          </el-table-column>
          <el-table-column
              prop="treatment"
              width="300"
              label="治疗方式">
          </el-table-column>
          <el-table-column label="关节镜所见">
            <el-table-column
                prop="is_meniscus"
                width="200"
                label="是否是膝关节外侧盘状半月板">
            </el-table-column>
            <el-table-column
                prop="meniscus_type"
                width="200"
                label="膝关节外侧盘状半月板类型">
            </el-table-column>
            <el-table-column
                prop="is_meniscus_tear"
                width="200"
                label="膝关节外侧盘状半月板是否撕裂">
            </el-table-column>
            <el-table-column
                prop="meniscus_joint"
                width="200"
                label="膝关节外侧盘状半月板撕裂部位">
            </el-table-column>
            <el-table-column
                prop="other_meniscus_joint"
                width="200"
                label="其他">
            </el-table-column>
            <el-table-column
                prop="connor"
                width="200"
                label="外侧半月板损伤O'Connor 分型">
            </el-table-column>
            <el-table-column
                prop="is_cartilage_damage"
                width="200"
                label="膝关节是否具有关节软骨损伤">
            </el-table-column>
            <el-table-column
                prop="clinical_symptoms"
                width="200"
                label="膝关节软骨损伤发生部位">
            </el-table-column>
            <el-table-column
                prop="outerbridge_level"
                width="220"
                label="关节镜下Outerbridge软骨损伤分级">
            </el-table-column>
            <el-table-column
                prop="lcrs_level"
                width="200"
                label="关节镜下ICRS软骨损伤分级">
            </el-table-column>
            <el-table-column
                prop="is_meniscus_discoid"
                width="200"
                label="内侧半月板是否为盘状半月板">
            </el-table-column>
            <el-table-column
                prop="is_meniscus_torn"
                width="200"
                label="内侧半月板是否撕裂">
            </el-table-column>
            <el-table-column
                prop="medial_discoid_meniscus"
                width="200"
                label="膝关节内侧盘状半月板撕裂部位">
            </el-table-column>
            <el-table-column
                prop="other_medial_discoid_meniscus"
                width="200"
                label="其他">
            </el-table-column>
            <el-table-column
                prop="inside_connor"
                width="200"
                label="内侧半月板损伤O'Connor 分型">
            </el-table-column>
          </el-table-column>
          <el-table-column label="术后并发症">
            <el-table-column
                prop="operation_complications"
                width="300"
                label="术后并发症">
            </el-table-column>
            <el-table-column
                prop="other_complications"
                width="300"
                label="其他">
            </el-table-column>
          </el-table-column>
          <el-table-column
              prop="bearing_time"
              width="110"
              label="术后负重时间">
          </el-table-column>
          <el-table-column
              prop="is_exercise"
              width="300"
              label="术后是否进行规范化、系统性功能锻炼">
          </el-table-column>
          <el-table-column label="术后随访">
            <el-table-column label="术后疗效评估">
              <el-table-column label="左">
                <el-table-column
                    prop="cruorin"
                    width="110"
                    label="VAS 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lysholm_left"
                    width="110"
                    label="Lysholm 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_tegner_left"
                    width="110"
                    label="Tegner 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lkdc_left"
                    width="110"
                    label="IKDC 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lkeuchi_left"
                    width="110"
                    label="Ikeuchi 分级">
                </el-table-column>
              </el-table-column>
              <el-table-column label="右">
                <el-table-column
                    prop="follow_vas_right"
                    width="110"
                    label="VAS 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lysholm_right"
                    width="110"
                    label="Lysholm 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_tegner_right"
                    width="110"
                    label="Tegner 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lkdc_right"
                    width="110"
                    label="IKDC评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lkeuchi_right"
                    width="110"
                    label="Ikeuchi 分级">
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <el-table-column label="随访期间对膝关节不适的处理措施">
              <el-table-column
                  prop="treatment_measures"
                  width="300"
                  label="随访期间对膝关节不适的处理措施">
              </el-table-column>
            </el-table-column>
            <el-table-column label="其他需要特殊说明的情况">
              <el-table-column
                  prop="remark_special"
                  width="300"
                  label="其他需要特殊说明的情况">
              </el-table-column>
            </el-table-column>
          </el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <i class="el-icon-edit-outline edit" v-if="scope.row.is_edit == 1" @click="openPage(scope.row.id)"></i>
              <i class="el-icon-search search" @click="lookDetail(scope.row.id)"></i>
              <i class="el-icon-document edit" @click="lookLog(scope.row.id)"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
            background
            layout="prev, pager, next"
            @current-change="changePage"
            :total="count">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="日志信息" style="text-align: left;" :visible.sync="dialogVisible">
      <el-table :data="log" size="mini" border>
        <el-table-column property="member_name" label="操作人" width="150"></el-table-column>
        <el-table-column property="create_date" label="操作时间" width="200"></el-table-column>
        <el-table-column property="content" label="操作内容"></el-table-column>
      </el-table>
      <el-pagination
          stripe
          style="margin-top:30px;"
          small
          @current-change="changeLogPage"
          layout="prev, pager, next"
          :total="logCount">
      </el-pagination>
    </el-dialog>
    <el-dialog title="导出" style="text-align: left;" :visible.sync="exportVisible" width="840px">
      <div>请选择需要导出的信息项</div>
      <div>
        <el-checkbox-group style="margin-top: 20px;width: 800px" v-model="treatment">
          <div class="dao">
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="1">I .人口统计学及入院信息</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="2">II.实验室检查结果</el-checkbox>
          </div>
          <div class="dao">
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="3">III.影像学检查结果</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="4">IV.术前膝关节功能评分</el-checkbox>
          </div>
          <div class="dao">
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="5">V.手术时年龄</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="6">VI.治疗方式</el-checkbox>
          </div>
          <div class="dao">
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="7">VII.关节镜所见</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="8">VIII.术后并发症</el-checkbox>
          </div>
          <div class="dao">
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="9">IX.术后负重时间</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="10">X.术后是否进行规范化、系统性功能锻炼</el-checkbox>
          </div>
          <div class="dao">
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="11">XI.术后疗效评估</el-checkbox>
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="12">XII.随访期间对膝关节不适的处理措施</el-checkbox>
          </div>
          <div class="dao">
            <el-checkbox style="margin-bottom:8px;width: 50%;" :label="13">XIII.其他需要特殊说明的情况</el-checkbox>
          </div>
        </el-checkbox-group>
        <span slot="footer" class="dialog-footer">
                    <el-button @click="restore" size="small">恢复默认</el-button>
                    <el-button type="primary" size="small" @click="exportTable">确 定</el-button>
                </span>
      </div>
    </el-dialog>
    <el-drawer
        title="全部展示"
        :visible.sync="table"
        style="text-align:left"
        direction="rtl"
        size="70%">
      <div class="drawer_box">
        <div class="table_top">
          <div class="table_left">
            <el-input
                placeholder="请输入"
                suffix-icon="el-icon-search"
                size="small"
                style="width: 280px"
                v-model="name_test">
            </el-input>
            <el-button size="small" style="margin-left: 20px" @click="getAllList">查询</el-button>
            <img class="tan" src="../assets/images/u715.png" alt="">
            <span class="tan_txt">共{{ allCount ? allCount : 0 }}条，选中{{ selectArr.length }}条</span>
          </div>
          <div class="table_right">
            <el-button size="small" type="primary" @click="exportList">导出excel</el-button>
          </div>
        </div>
        <el-table
            ref="multipleAllTable "
            :data="allTable"
            size="mini"
            border
            height="550"
            header-cell-style="background:#f8f8fa"
            style="margin-top: 30px"
            @selection-change="handleAllTable"
        >
          <el-table-column label="人口统计学及入院信息">
            <el-table-column
                type="selection"
                fixed
                width="55">
            </el-table-column>
            <el-table-column
                prop="name"
                label="受检者项目"
                fixed
                width="150"
            >
            </el-table-column>
            <el-table-column
                prop="card_num"
                width="160"
                label="身份证号">
            </el-table-column>
            <el-table-column
                prop="reg_num"
                width="110"
                label="登记号">
            </el-table-column>
            <el-table-column
                prop="op_num"
                width="110"
                label="门诊号">
            </el-table-column>
            <el-table-column
                prop="ip_num"
                width="110"
                label="住院号">
            </el-table-column>
            <el-table-column
                prop="ep_num"
                width="110"
                label="急诊号">
            </el-table-column>
            <el-table-column
                prop="sample_num"
                width="130"
                label="样本编号">
            </el-table-column>
            <el-table-column
                prop="birthday"
                width="110"
                label="出生日期">
            </el-table-column>
            <el-table-column
                prop="member_age"
                width="110"
                label="当前年龄">
            </el-table-column>
            <el-table-column
                prop="address"
                width="130"
                label="长期居住地址">
            </el-table-column>
            <el-table-column
                prop="tel"
                width="110"
                label="电话号码">
            </el-table-column>
            <el-table-column
                prop="age"
                width="110"
                label="入院时年龄">
            </el-table-column>
            <el-table-column
                prop="gender"
                width="110"
                label="性别">
            </el-table-column>
            <el-table-column
                prop="weight"
                width="110"
                label="体重">
            </el-table-column>
            <el-table-column
                prop="height"
                width="110"
                label="身高">
            </el-table-column>
            <el-table-column
                prop="mass"
                width="110"
                label="体重指数(kg/m)">
            </el-table-column>
            <el-table-column
                prop="race"
                width="110"
                label="种族">
            </el-table-column>
            <el-table-column
                prop="nation"
                width="110"
                label="民族">
            </el-table-column>
            <el-table-column
                prop="inherit"
                width="150"
                label="是否具有家族遗传病史">
            </el-table-column>
            <el-table-column
                prop="inherit_remark"
                width="110"
                label="家族遗传病说明">
            </el-table-column>
            <el-table-column
                prop="gather"
                width="300"
                label="是否具有膝关节外侧盘状半月板家族聚集现象">
            </el-table-column>
            <el-table-column
                width="110"
                align="center"
                label="家系遗传图">
              <template slot-scope="scope">
                <el-image
                    v-if="scope.row.images && scope.row.images.length"
                    style="width: 40px; height: 40px"
                    fit="contain"
                    :src="scope.row.images[0]"
                    :preview-src-list="scope.row.images">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
                prop="levels"
                width="150"
                label="日常生活及工作强度">
            </el-table-column>
            <el-table-column
                prop="knee_join"
                width="170"
                label="膝关节有无明确受伤病史">
            </el-table-column>
            <el-table-column
                prop="knee_join_remark"
                width="110"
                label="因何种方式受伤">
            </el-table-column>
            <el-table-column
                prop="joint_side_title"
                width="160"
                label="出现临床症状的关节侧别">
            </el-table-column>
            <el-table-column
                prop="first_year_left_knee"
                width="200"
                label="首次出现临床症状时年龄-左膝">
            </el-table-column>
            <el-table-column
                prop="first_year_right_knee"
                width="200"
                label="首次出现临床症状时年龄-右膝">
            </el-table-column>
            <el-table-column
                prop="last_time_left_knee"
                width="150"
                label="症状持续时间(月)-左膝">
            </el-table-column>
            <el-table-column
                prop="last_time_right_knee"
                width="150"
                label="症状持续时间(月)-右膝">
            </el-table-column>
            <el-table-column
                prop="later_joint"
                width="200"
                label="临床症状的主要表现">
            </el-table-column>
            <el-table-column
                prop="other_later_joint"
                width="110"
                label="其他症状">
            </el-table-column>
          </el-table-column>
          <el-table-column label="实验室检查结果">
            <el-table-column
                prop="cruorin"
                width="110"
                label="血红蛋白">
            </el-table-column>
            <el-table-column
                prop="white_cells"
                width="110"
                label="白细胞">
            </el-table-column>
            <el-table-column
                prop="neutrophil"
                width="110"
                label="中性粒细胞">
            </el-table-column>
            <el-table-column
                prop="neutrophil_percent"
                width="110"
                label="中性粒细胞(%)">
            </el-table-column>
            <el-table-column
                prop="platelet"
                width="110"
                label="血小板">
            </el-table-column>
            <el-table-column
                prop="albumin"
                width="110"
                label="血蛋白">
            </el-table-column>
            <el-table-column
                prop="aspartate"
                width="110"
                label="谷草转氨酶">
            </el-table-column>
            <el-table-column
                prop="alanine"
                width="110"
                label="谷丙转氨酶">
            </el-table-column>
            <el-table-column
                prop="bilirubin"
                width="110"
                label="直接胆红素">
            </el-table-column>
            <el-table-column
                prop="indirect"
                width="110"
                label="间接胆红素">
            </el-table-column>
            <el-table-column
                prop="glucose"
                width="110"
                label="空腹血糖">
            </el-table-column>
            <el-table-column
                prop="nitrogen"
                width="110"
                label="尿素氮">
            </el-table-column>
            <el-table-column
                prop="creatinine"
                width="110"
                label="肌酐">
            </el-table-column>
            <el-table-column
                prop="uric_acid"
                width="110"
                label="血尿酸">
            </el-table-column>
            <el-table-column
                prop="k_jia"
                width="110"
                label="K+">
            </el-table-column>
            <el-table-column
                prop="Na_jia"
                width="110"
                label="Na+">
            </el-table-column>
            <el-table-column
                prop="rheumatoid"
                width="110"
                label="类风湿因子">
            </el-table-column>
            <el-table-column
                prop="anti_streptolysin"
                width="180"
                label="类风湿因子抗链球菌溶血素">
            </el-table-column>
            <el-table-column
                prop="reactive_protein"
                width="110"
                label="C反应蛋白">
            </el-table-column>
            <el-table-column
                prop="esr"
                width="110"
                label="血沉（ESR）">
            </el-table-column>
            <el-table-column
                prop="special_result"
                width="190"
                label="其他特殊实验室检测结果">
            </el-table-column>
          </el-table-column>
          <el-table-column label="影像学检查结果">
            <el-table-column label="膝关节X线片">
              <el-table-column
                  prop="joint_level"
                  width="120"
                  label="骨关节炎K-L 分级">
              </el-table-column>
              <el-table-column
                  prop="level_A"
                  width="110"
                  label="膝关节X线片A">
              </el-table-column>
              <el-table-column
                  prop="level_B"
                  width="110"
                  label="膝关节X线片B">
              </el-table-column>
              <el-table-column
                  prop="level_C"
                  width="110"
                  label="膝关节X线片C">
              </el-table-column>
              <el-table-column
                  prop="level_D"
                  width="110"
                  label="膝关节X线片D">
              </el-table-column>
            </el-table-column>
            <el-table-column label="膝关节核磁共振">
              <el-table-column
                  prop="nuclear"
                  width="180"
                  label="是否是膝关节外侧盘状半月板">
              </el-table-column>
              <el-table-column
                  prop="joint_knee"
                  width="180"
                  label="膝关节外侧盘状半月板类型">
              </el-table-column>
              <el-table-column
                  prop="is_tear"
                  width="200"
                  label="膝关节外侧盘状半月板是否撕裂">
              </el-table-column>
              <el-table-column
                  prop="slit_type"
                  width="200"
                  label="膝关节外侧盘状半月板撕裂类型">
              </el-table-column>
              <el-table-column
                  prop="tear_site"
                  width="200"
                  label="膝关节外侧盘状半月板撕裂部位">
              </el-table-column>
              <el-table-column
                  prop="other_tear_site"
                  width="110"
                  label="其他">
              </el-table-column>
              <el-table-column
                  prop="injury"
                  width="200"
                  label="膝关节是否具有关节软骨损伤">
              </el-table-column>
              <el-table-column
                  prop="cartilage_side"
                  width="200"
                  label="膝关节软骨损伤发生部位">
              </el-table-column>
              <el-table-column
                  prop="injury_level"
                  width="200"
                  label="膝关节磁共振软骨损伤分级">
              </el-table-column>
            </el-table-column>
            <el-table-column label="彩色多普勒">
              <el-table-column
                  prop="meniscus"
                  width="200"
                  label="是否是膝关节外侧盘状半月板">
              </el-table-column>
              <el-table-column
                  prop="lateral_discoid"
                  width="200"
                  label="膝关节外侧盘状半月板类型">
              </el-table-column>
              <el-table-column
                  prop="meniscus_tear"
                  width="200"
                  label="膝关节外侧盘状半月板是否撕裂">
              </el-table-column>
              <el-table-column
                  prop="doppler_slit_type"
                  width="200"
                  label="膝关节外侧盘状半月板撕裂类型">
              </el-table-column>
              <el-table-column
                  prop="tear_meniscus"
                  width="200"
                  label="膝关节外侧盘状半月板撕裂部位">
              </el-table-column>
              <el-table-column
                  prop="other_tear_meniscus"
                  width="110"
                  label="其他">
              </el-table-column>
              <el-table-column
                  prop="diagnosis"
                  width="200"
                  label="术前主要诊断">
              </el-table-column>
              <el-table-column
                  prop="preoperative"
                  width="200"
                  label="术前合并膝关节病损">
              </el-table-column>
              <el-table-column
                  prop="other_preoperative"
                  width="200"
                  label="其他膝关节疾病">
              </el-table-column>
            </el-table-column>
          </el-table-column>
          <el-table-column label="术前膝关节功能评分">
            <el-table-column label="左">
              <el-table-column
                  prop="vas_left"
                  width="110"
                  label="VAS 评分">
              </el-table-column>
              <el-table-column
                  prop="lysholm_left"
                  width="110"
                  label="Lysholm 评分">
              </el-table-column>
              <el-table-column
                  prop="tegner_left"
                  width="110"
                  label="Tegner 评分">
              </el-table-column>
              <el-table-column
                  prop="ikdc_left"
                  width="110"
                  label="IKDC 评分">
              </el-table-column>
              <el-table-column
                  prop="lkeuchi_left"
                  width="110"
                  label="Ikeuchi 分级">
              </el-table-column>
            </el-table-column>
            <el-table-column label="右">
              <el-table-column
                  prop="vas_right"
                  width="110"
                  label="VAS 评分">
              </el-table-column>
              <el-table-column
                  prop="lysholm_right"
                  width="110"
                  label="Lysholm 评分">
              </el-table-column>
              <el-table-column
                  prop="tegner_right"
                  width="110"
                  label="Tegner 评分">
              </el-table-column>
              <el-table-column
                  prop="ikdc_right"
                  width="110"
                  label="IKDC 评分">
              </el-table-column>
              <el-table-column
                  prop="lkeuchi_right"
                  width="110"
                  label="Ikeuchi 分级">
              </el-table-column>
            </el-table-column>
          </el-table-column>
          <el-table-column
              prop="operation_age"
              width="110"
              label="手术时年龄">
          </el-table-column>
          <el-table-column
              prop="treatment"
              width="300"
              label="治疗方式">
          </el-table-column>
          <el-table-column label="关节镜所见">
            <el-table-column
                prop="is_meniscus"
                width="200"
                label="是否是膝关节外侧盘状半月板">
            </el-table-column>
            <el-table-column
                prop="meniscus_type"
                width="200"
                label="膝关节外侧盘状半月板类型">
            </el-table-column>
            <el-table-column
                prop="is_meniscus_tear"
                width="200"
                label="膝关节外侧盘状半月板是否撕裂">
            </el-table-column>
            <el-table-column
                prop="meniscus_joint"
                width="200"
                label="膝关节外侧盘状半月板撕裂部位">
            </el-table-column>
            <el-table-column
                prop="other_meniscus_joint"
                width="200"
                label="其他">
            </el-table-column>
            <el-table-column
                prop="connor"
                width="200"
                label="外侧半月板损伤O'Connor 分型">
            </el-table-column>
            <el-table-column
                prop="is_cartilage_damage"
                width="200"
                label="膝关节是否具有关节软骨损伤">
            </el-table-column>
            <el-table-column
                prop="clinical_symptoms"
                width="200"
                label="膝关节软骨损伤发生部位">
            </el-table-column>
            <el-table-column
                prop="outerbridge_level"
                width="220"
                label="关节镜下Outerbridge软骨损伤分级">
            </el-table-column>
            <el-table-column
                prop="lcrs_level"
                width="200"
                label="关节镜下ICRS软骨损伤分级">
            </el-table-column>
            <el-table-column
                prop="is_meniscus_discoid"
                width="200"
                label="内侧半月板是否为盘状半月板">
            </el-table-column>
            <el-table-column
                prop="is_meniscus_torn"
                width="200"
                label="内侧半月板是否撕裂">
            </el-table-column>
            <el-table-column
                prop="medial_discoid_meniscus"
                width="200"
                label="膝关节内侧盘状半月板撕裂部位">
            </el-table-column>
            <el-table-column
                prop="other_medial_discoid_meniscus"
                width="200"
                label="其他">
            </el-table-column>
            <el-table-column
                prop="inside_connor"
                width="200"
                label="内侧半月板损伤O'Connor 分型">
            </el-table-column>
          </el-table-column>
          <el-table-column label="术后并发症">
            <el-table-column
                prop="operation_complications"
                width="300"
                label="术后并发症">
            </el-table-column>
            <el-table-column
                prop="other_complications"
                width="300"
                label="其他">
            </el-table-column>
          </el-table-column>
          <el-table-column
              prop="bearing_time"
              width="110"
              label="术后负重时间">
          </el-table-column>
          <el-table-column
              prop="is_exercise"
              width="300"
              label="术后是否进行规范化、系统性功能锻炼">
          </el-table-column>
          <el-table-column label="术后随访">
            <el-table-column label="术后疗效评估">
              <el-table-column label="左">
                <el-table-column
                    prop="cruorin"
                    width="110"
                    label="VAS 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lysholm_left"
                    width="110"
                    label="Lysholm 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_tegner_left"
                    width="110"
                    label="Tegner 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lkdc_left"
                    width="110"
                    label="IKDC 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lkeuchi_left"
                    width="110"
                    label="Ikeuchi 分级">
                </el-table-column>
              </el-table-column>
              <el-table-column label="右">
                <el-table-column
                    prop="follow_vas_right"
                    width="110"
                    label="VAS 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lysholm_right"
                    width="110"
                    label="Lysholm 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_tegner_right"
                    width="110"
                    label="Tegner 评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lkdc_right"
                    width="110"
                    label="IKDC评分">
                </el-table-column>
                <el-table-column
                    prop="follow_lkeuchi_right"
                    width="110"
                    label="Ikeuchi 分级">
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <el-table-column label="随访期间对膝关节不适的处理措施">
              <el-table-column
                  prop="treatment_measures"
                  width="300"
                  label="随访期间对膝关节不适的处理措施">
              </el-table-column>
            </el-table-column>
            <el-table-column label="其他需要特殊说明的情况">
              <el-table-column
                  prop="remark_special"
                  width="300"
                  label="其他需要特殊说明的情况">
              </el-table-column>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
    <page :id="id" v-if="visible"></page>
    <detail :id="id" v-if="detailVistable"></detail>
  </div>
</template>

<script>
import page from './form_page'
import detail from './detail'
import {baseURL} from '../config'

export default {
  name: "Inquire",
  components: {page, detail},
  data() {
    return {
      dialogVisible: false,
      exportVisible: false,
      table: false,
      old: '',
      newPassWord: '',
      treatment: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      selectArr: [],
      options: [{
        value: 1,
        label: '男'
      }, {
        value: 2,
        label: '女'
      }],
      typeArr: [{
        value: 1,
        label: '身份证号'
      }, {
        value: 2,
        label: '登记号'
      }, {
        value: 3,
        label: '住院号'
      }, {
        value: 4,
        label: '门诊号'
      }, {
        value: 5,
        label: '急诊号'
      }, {
        value: 6,
        label: '样本编号'
      }],
      color: [{
        value: '黄种人',
        label: '黄种人'
      }, {
        value: '白种人',
        label: '白种人'
      }, {
        value: '黑种人',
        label: '黑种人'
      }, {
        value: '棕种人',
        label: '棕种人'
      }],
      type: 1,
      search_data: [],
      name: [],
      gender: '',
      race: [],
      age_start: '',
      age_end: '',
      member_age_start: '',
      member_age_end: '',
      nation_list: [],
      nation: [],
      dateValue: '',
      create_start: '',
      create_end: '',
      person_list: '',
      person_id: [],
      page: 1,
      count: '',
      empty: false,
      tableData: [],
      log: [],
      allTable: [],
      logPage: 1,
      logCount: '',
      input1: '',
      allCount: 0,
      name_txt: '',
      name_test: '',
      id: 0,
      visible: false,
      detailVistable: false
    }
  },
  watch: {
    table(val) {
      if (val == false) {
        this.$refs.multipleAllTable.clearSelection();
      }
    }
  },
  methods: {
    changeDate() {
      this.create_start = this.dateValue[0];
      this.create_end = this.dateValue[1]
    },
    handleSelectionChange(val) {
      this.selectArr = [];
      val.forEach(item => {
        this.selectArr.push(item.id)
      })
    },
    restore() {
      this.treatment = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
    },
    resetData() {
      this.type = 1
      this.search_data = []
      this.name = []
      this.gender = ''
      this.race = []
      this.member_age_start = ''
      this.member_age_end = ''
      this.nation = []
      this.person_id = []
      this.age_start = ''
      this.age_end = ''
      this.dateValue = ''
    },
    handleAllTable(val) {
      this.selectArr = [];
      val.forEach(item => {
        this.selectArr.push(item.id)
      })
    },
    exportList() {
      this.treatment = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
      if (this.selectArr.length === 0) {
        this.$notify({
          title: '提醒',
          message: '请先选择导出列表',
          type: 'warning'
        });
      } else {
        this.exportVisible = true
      }
    },
    exportTable() {
      let a = this.selectArr.join(',')
      let b = this.treatment.join(',')
      // window.location.href=`https://wcb-wenjuan.mini.bandaochina.com/api/Config/question_export?question_id=${a}&type=${b}`

      window.open(`${baseURL}/api/Config/question_export?question_id=${a}&type=${b}`)
      this.selectArr = [];
      this.exportVisible = false
    },
    openPage(id) {
      this.id = id
      this.visible = !this.visible
      console.log(this.id)
    },
    lookLog(id) {
      this.dialogVisible = true
      this.$request('/Question/question_log', {page: this.logPage, question_id: id}).then((res) => {
        this.log = res.data.data
        this.logCount = res.data.count
      })
    },
    lookDetail(id) {
      this.id = id
      console.log(this.id)
      this.detailVistable = !this.detailVistable
    },
    showTable() {
      this.selectArr = [];
      this.$refs.multipleTable.clearSelection();
      this.table = true
      this.getAllList();
    },
    getNation() {
      this.$request('/Config/nation_list').then((res) => {
        this.nation_list = res.data
      })
    },
    getPerson() {
      this.$request('/Member/member_list').then((res) => {
        this.person_list = res.data
      })
    },
    changePage(val) {
      this.page = val
      this.getList()
    },
    changeLogPage(val) {
      this.logPage = val
      this.lookLog()
    },
    getList() {
      setTimeout(() => {
        let data = {
          type: this.type,
          search_data: String(this.search_data),
          name: String(this.name),
          gender: this.gender,
          age_start: this.age_start,
          age_end: this.age_end,
          member_age_start: this.member_age_start,
          member_age_end: this.member_age_end,
          race: String(this.race),
          nation: String(this.nation),
          create_start: this.create_start,
          create_end: this.create_end,
          user_id: String(this.person_id),
          page: this.page,
          crux_title: this.name_txt
        }
        this.$request('/Question/search_question', data).then((res) => {
          this.tableData = res.data.data
          this.count = res.data.count
          this.empty = true
        })
      }, 200)
    },
    getAllList() {
      this.$request('/Question/all_question', {crux_title: this.name_test}).then((res) => {
        this.allTable = res.data.data
        this.allCount = res.data.count
      })
    }
  },
  created() {
    this.getNation();
    this.getPerson();
  },
  beforeCreate() {
    document
        .querySelector('body')
        .setAttribute('style', 'background-color:#f0f2f5')
  }
}
</script>

<style scoped lang="less">
input::-webkit-input-placeholder {
  color: #c0c4cc;
}

.main {
  padding-top: 10px;

  .news {
    width: 1180px;
    padding: 0 10px;
    padding-bottom: 30px;
    background: #fff;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .news_left {
    width: 50%;
  }

  .news_right {
    width: 50%;
  }

  .news_title {
    display: flex;
    align-items: center;
    height: 55px;

    .title_line {
      height: 25px;
      border-left: 2px solid #226527;
      margin-right: 8px;
    }

    .news_name {
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      color: #2F3031;
    }
  }

  .news_form {
    border-right: 1px dotted #e7ebef;

    .rows {
      display: flex;
      align-items: center;
      margin-top: 14px;
    }

    .row_right {
      display: flex;
      align-items: center;
      margin-left: 20px;

      .right_name {
        width: 50px;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: #325562;
        text-align: right;
        margin-right: 10px;
      }
    }
  }

  .row_left {
    display: flex;
    align-items: center;

    .left_name {
      width: 100px;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: #325562;
      text-align: right;
      margin-right: 10px;
    }

    .year {
      width: 178px;
      height: 30px;
      border: 1px solid #dcdfe6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      color: #c0c4cc;

      .num {
        width: 60px;
        border: none;
        text-align: center;
        outline: none;
      }
    }
  }
}

.btn_box {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}

.empty {
  width: 97px;
  height: 100px;
  margin-top: 100px;
}

.empty_txt {
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: #929DA2;
}

.empty_tips {
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  color: #AFBABF;
  line-height: 28px;
}

.table_box {
  padding: 30px;
  width: 1140px;
  min-height: 600px;
  background: #fff;
  margin: 20px auto;
}

.table_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table_left {
  display: flex;
  align-items: center;
}

.tan {
  width: 15px;
  height: 15px;
  margin: 0 10px;
}

.tan_txt {
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #333333;
}

.edit {
  font-size: 20px;
  color: #226527;
}

.search {
  font-size: 20px;
  color: #226527;
  margin: 0 10px;
}

.page {
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
}

.drawer_box {
  padding: 20px;
}

.cha {
  color: #226527;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.dao {
  display: flex;
  justify-content: space-between;
}

.dialog-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
