<template>
    <div class="main">
        <div class="count_top">
            <div class="line"></div>
            <div class="line-txt">统计</div>
        </div>
        <div class="list">
            <div class="item">
                <div>
                    <div class="item_title">问卷总数统计</div>
                    <div class="item_num">{{count}}</div>
                </div>
                <div class="item_right">
                    <img class="item_icon" src="../assets/images/u3053.svg" alt="">
                </div>
            </div>
        </div>
        <div class="list">
            <div class="item">
                <div>
                    <div class="item_title_a">人类膝关节外侧盘状半月板遗传方式及致病基因研究信息提取表</div>
                    <div class="item_num_a">{{count}}</div>
                </div>
                <div class="item_right" style="background: #267330;">
                    <img class="item_icon_a" src="../assets/images/u3055.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "count",
        data() {
            return {
                count:''
            }
        },
        methods:{
            getCount() {
                this.$request('/Question/census_question').then( (res) => {
                    this.count = res.data
                })
            }
        },
        created() {
          this.getCount();
        },
        beforeCreate() {
            document
                .querySelector('body')
                .setAttribute('style', 'background-color:#f0f2f5')
        }
    }
</script>

<style scoped lang="scss">
    .main {
        padding-top: 10px;
    }
    .count_top {
        width: 1180px;
        margin: 0 auto;
        padding: 14px 10px;
        background: #fff;
        display: flex;
    }
    .line {
        border-left: 2px solid #226527;
    }
    .line-txt {
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        color: #2F3031;
        margin-left: 14px;
    }
    .list {
        display: flex;
        width: 1200px;
        margin: 20px auto;
    }
    .item {
        padding: 20px;
        background: #fff;
        width: 348px;
        height: 80px;
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        border: 1px solid #e9e9e9;
    }
    .item_right {
        width: 80px;
        height: 80px;
        background: #72a959;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        flex: 0 0 auto;
        margin-left: 16px;
        .item_icon {
            width: 49px;
            height: 40px;
        }
    }
    .item_icon_a {
        width: 37px;
        height: 43px;
    }
    .item_title {
        font-weight: 400;
        font-style: normal;
        color: rgba(102, 102, 102, 0.847058823529412);
        font-size: 14px;
    }
    .item_title_a {
        font-weight: 400;
        font-style: normal;
        color: rgba(102, 102, 102, 0.847058823529412);
        font-size: 14px;
        text-align: left;
    }
    .item_num {
        font-weight: 700;
        font-size: 32px;
        color: #267330;
        margin-top: 30px;
        text-align: left;
    }
    .item_num_a {
        font-weight: 700;
        font-size: 32px;
        color: #267330;
        margin-top: 10px;
        text-align: left;
    }
</style>