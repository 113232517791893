<template>
  <div class="about">
    <div class="nav">
        <div class="nav_title">WCB随访问卷</div>
        <div class="nav_right">
          <div :class="['nav_item',{'nav_item_a':int == 0}]" @click="goIndex">
            <img class="nav_icon" src="../assets/images/index.svg" alt="">
            <div class="nav_txt">工作台</div>
          </div>
          <div :class="['nav_item',{'nav_item_a':int == 1}]" @click="goSearch">
            <img class="nav_icon_a" src="../assets/images/yuan.svg" alt="">
            <div class="nav_txt">综合查询</div>
          </div>
          <div :class="['nav_item',{'nav_item_a':int == 2}]" @click="goData">
            <img class="nav_icon_a" src="../assets/images/qin.svg" alt="">
            <div class="nav_txt">统计分析</div>
          </div>
          <div :class="['nav_item',{'nav_item_a':int == 3}]" @click="goNews">
            <img class="nav_icon_a" src="../assets/images/news.svg" alt="">
            <div class="nav_txt">消息</div>
          </div>
          <div class="nav_name">{{name}}</div>
          <el-dropdown>
            <img class="close" src="../assets/images/u264.svg" alt="">
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="out">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
    </div>
    <div style="width:100%;height:70px;"></div>
    <router-view></router-view>
  </div>
</template>
<script>
  export default  {
    name: 'About',
    data() {
      return {
          name:'',
          int:0
      }
    },
    methods: {
      handleClick() {
        this.$router.push({
          path:'/modify'
        })
      },
      out() {
        localStorage.removeItem('token');
        this.$router.replace({
          path:'/Home'
        })
      },
      goIndex() {
        this.int = 0
        localStorage.setItem('nav',this.int)
        this.$router.replace({
          path:'/'
        })
      },
      goSearch() {
        this.int = 1
        localStorage.setItem('nav',this.int)
        this.$router.replace({
          path:'/Inquire'
        })
      },
      goData() {
        this.int = 2
        localStorage.setItem('nav',this.int)
        this.$router.replace({
          path:'/count'
        })
      },
      goNews() {
        this.int = 3
        localStorage.setItem('nav',this.int)
        this.$router.replace({
          path:'/news'
        })
      }
    },
    created() {
      if(!localStorage.getItem('token')) {
        this.$router.replace({
          path:'/Home'
        })
      }
      this.name = localStorage.getItem('name');
      // this.int = localStorage.getItem('nav')?localStorage.getItem('nav'):0
    }
  }
</script>
<style scoped lang="scss">
  .nav {
    width: 100%;
    height: 70px;
    background: url("../assets/images/u261_state0.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav_icon {
    width: 22px;
    height: 24px;
  }
  .nav_icon_a {
    width: 24px;
    height: 24px;
  }
  .nav_txt {
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    color: #FFFFFF;
    margin-top: 6px;
  }
  .nav_right {
    display: flex;
    align-items: center;
    margin-right: 35px;
  }
  .nav_title {
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    color: #FFFFFF;
    margin-left: 35px;
  }
  .nav_item {
    width: 103px;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .nav_item:hover {
    background: #1c5d22;
  }
  .nav_item_a {
    background: #1c5d22;
  }
  .nav_name {
    width: 84px;
    height: 70px;
    font-weight: 400;
    font-style: normal;
    color: #FFFFFF;
    font-size: 13px;
    line-height: 70px;
    text-align: center;
  }
  .close {
    width: 24px;
    height: 24px;
    margin: 0 20px;
  }
</style>
