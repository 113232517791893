<template>
    <div class="main">
        <div class="news">
            <div class="news_top">消息</div>
            <div>
                <img class="empty" src="../assets/images/empty.svg" alt="">
                <div class="empty_txt">数据为空</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "news",
        data() {
            return {

            }
        },
        beforeCreate() {
            document
                .querySelector('body')
                .setAttribute('style', 'background-color:#f0f2f5')
        }
    }
</script>

<style scoped lang="scss">
    .main {
        padding-top: 10px;
        .news {
            width: 1180px;
            padding: 0 10px;
            background: #fff;
            height: 716px;
            margin: 0 auto;
            .news_top {
                padding: 0 20px;
                height: 50px;
                border-bottom: 1px solid #e4e4e4;
                ont-weight: 400;
                font-style: normal;
                font-size: 16px;
                color: #333;
                text-align: left;
                line-height: 50px;
            }
            .empty {
                width: 100px;
                height: 100px;
                margin-top: 50px;
            }
            .empty_txt {
                font-weight: 600;
                font-style: normal;
                font-size: 16px;
                color: #929DA2;
                text-align: center;
            }
        }
    }
</style>